import { Button, Container, Flex, Heading, Text, TextField } from '@radix-ui/themes'
import { Helmet } from 'react-helmet-async'
import { useState } from 'react'
import { Check } from 'lucide-react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { useNavigate } from 'react-router-dom'
import { Content } from '~/views/layout/Content.tsx'
import { cn } from '~/libs/utils'
import { apiFetch } from '~/libs/fetcher'
import { fetchAllTeams, setCurrentTeam } from '~/stores/team'
import { ManageMember } from '~/views/team/ManageMember'

interface IFormInput {
  name: string
}

export function CreateTeam() {
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { isSubmitting, errors } } = useForm({
    defaultValues: {
      name: '',
    },
  })

  const [step, setStep] = useState(1)
  const [teamId, setTeamId] = useState('')

  const handleCreateTeam: SubmitHandler<IFormInput> = async (data) => {
    setStep(2)
    let teamId: string = ''
    try {
      const res = await apiFetch('/teams/create', {
        method: 'POST',
        body: data,
      })
      if (res.ok)
        teamId = res.data.teamId
    }
    catch (error) {
      toast.error('Failed to create team')
      return
    }
    try {
      if (teamId) {
        await fetchAllTeams()
        await setCurrentTeam(teamId)
        setTeamId(teamId)
        setStep(2)
      }
    }
    catch (error) {
      toast.error('Failed to fetch teams!')
    }
  }

  const handleFinish = async () => {
    navigate(`/${teamId}`)
  }

  const steps = [
    {
      step: 1,
      title: 'Create team',
      children: (
        <form onSubmit={handleSubmit(handleCreateTeam)}>
          <Flex className="max-w-64 pt-4" direction="column" gap="3">
            <Text size="2" mb="1" weight="bold">
              Team name
            </Text>
            <TextField.Root
              size="2"
              {...register('name', { required: true, pattern: /^[a-zA-Z0-9._-]{1,100}$/ })}
              placeholder="Enter a team name"
            />
            {errors.name && (
              <div className="text-red-500">
                <div>Invalid name.</div>
                <div>Uppercase and lowercase letters, numbers, and ._- allowed</div>
                <div>Max 100 characters.</div>
              </div>
            )}
            <div>
              <Button type="submit" loading={isSubmitting}>Create team</Button>
            </div>
          </Flex>
        </form>
      ),
    },
    {
      step: 2,
      title: 'Invite members',
      children: (
        <Flex className="pt-4" direction="column" gap="3">
          <ManageMember />
          <div>
            <Button onClick={handleFinish}>Finish</Button>
          </div>
        </Flex>
      ),
    },
  ]
  return (
    <Content title="Create Team" row>
      <Helmet>
        <title>
          Create Team
        </title>
      </Helmet>
      <Container size="3" align="left">
        <Flex gap="6" direction="column">
          {steps.map((item, index) => {
            const isActive = item.step === step
            const isPass = item.step < step
            return (
              <Flex className="relative" direction="column" gap="4" key={item.step}>
                <div
                  className={cn('absolute z-10 flex h-8 w-8 items-center justify-center rounded-full border bg-[--accent-3] border-gray-400 text-gray-400', {
                    'bg-[--accent-indicator] text-[--accent-contrast]': isActive || isPass,
                  })}
                >
                  {isPass ? <Check className="size-4" /> : <Text size="2">{item.step}</Text>}
                </div>
                <div className="pl-10">
                  <Heading
                    size="4"
                    className={cn('text-gray-400 pt-1', {
                      'text-[--accent-9]': isActive || isPass,
                    })}
                  >
                    {item.title}
                  </Heading>
                  {isActive && item.children}
                </div>
                {index < steps.length - 1 && (
                  <div
                    className={cn('absolute left-4 top-8 -ml-px h-full w-px border-l border-dashed border-gray-400', {
                      'border-[--accent-9]': isPass,
                    })}
                    aria-hidden="true"
                  />
                )}
              </Flex>
            )
          })}
        </Flex>
      </Container>
    </Content>
  )
}
