import { matchPath } from 'react-router-dom'
import { fetchMe, userStore } from './user'
import { fetchAllTeams, setCurrentTeam, teamStore } from './team'
import { logto, signIn } from '~/libs/logto'

let isInitialized = false
export async function initStore() {
  if (isInitialized)
    return false
  isInitialized = true

  try {
    const isAuthenticated = await logto.isAuthenticated()
    if (!isAuthenticated) {
      signIn()
      return false
    }
  }
  catch (error) {
    signIn()
    return false
  }

  // fetch me must be called before fetchAllTeams
  await fetchMe()
  await fetchAllTeams()

  const match = matchPath('/:teamSlug/*', location.pathname)
  let teamSlug = ''
  if (match !== null)
    teamSlug = match.params.teamSlug!

  const allTeams = teamStore.allTeams

  if (teamSlug === '') {
    const me = userStore.me
    const defaultTeam = allTeams.find(team => team.id === me.defaultTeamId)
    if (defaultTeam)
      teamSlug = defaultTeam.slug
    else
      teamSlug = allTeams[0].slug
  }

  setCurrentTeam(teamSlug)

  return true
}
