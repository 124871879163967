import { Box, Card, Flex, Heading, Separator, Skeleton, Text } from '@radix-ui/themes'
import { Helmet } from 'react-helmet-async'
import numeral from 'numeral'
import { addMonths, differenceInCalendarDays, format, startOfMonth } from 'date-fns'
import { Bar, BarChart, YAxis } from 'recharts'
import { useEffect, useState } from 'react'
import ProgressRing from '~/components/ui/progress-ring'
import { useTeamStore } from '~/stores/team'
import { Content } from '~/views/layout/Content.tsx'
import { ChartContainer, ChartTooltip, ChartTooltipContent } from '~/components/ui/chart'
import { TokenUsage } from '~/views/team/TokenUsage'
import { apiBillingFetch } from '~/libs/fetcher'

export function TeamUsage() {
  const teamStore = useTeamStore()
  const [credits, setCredits] = useState<{ costs: number, credits: number, charges: number, qpm: number }>({
    costs: 0,
    credits: 0,
    qpm: 0,
    charges: 0,
  })
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (!teamStore.currentTeam.id)
      return
    const getData = async () => {
      setLoading(true)
      try {
        const res = await apiBillingFetch(`/v1/credits/${teamStore.currentTeam.id}`)
        if (!res.data)
          return
        setCredits(res.data)
      }
      finally {
        setLoading(false)
      }
    }
    getData()
  }, [teamStore.currentTeam.id])
  const getDayjsUnitNextBilling = () => {
    const currentDate = new Date()
    const nextMonthFirstDay = startOfMonth(addMonths(currentDate, 1))
    const daysUntilNextBilling = differenceInCalendarDays(nextMonthFirstDay, currentDate)
    return daysUntilNextBilling
  }
  const monthlySnapshot = [{ month: format(new Date(), 'MMMM'), costs: credits.costs }]
  return (
    <Content title="Usage">
      <Helmet>
        <title>
          Usage
        </title>
      </Helmet>
      <Box className="mx-auto w-full max-w-5xl">
        <Flex direction="column" gap="6">
          <Card>
            <Flex direction="column" gap="4">
              <Flex direction="row" gap="6" align="center">
                <Heading size="3" className="flex-1">
                  Usage Snapshot for
                  {' '}
                  <Text size="3" color="teal" className="font-normal">
                    {format(new Date(), 'MMMM yyyy')}
                  </Text>
                </Heading>
                <Text size="2" color="teal">
                  Next billing period starts in
                  {' '}
                  {getDayjsUnitNextBilling()}
                  {' '}
                  days
                </Text>
              </Flex>
              <Flex
                gap="3"
                wrap="wrap"
              >
                <Card className="w-full lg:w-80">
                  <Flex direction="column" gap="4" justify="center" className="h-full">
                    <Flex gap="4" justify="center">
                      <div className="w-[80px]">
                        <ProgressRing current={credits.costs} max={credits.credits} size={80} />
                      </div>
                      <Flex gap="2" direction="column" align="start">
                        <Text size="2">Credits</Text>
                        <Skeleton loading={loading}>
                          <Text size="2" color="gray">
                            {numeral(credits.credits / 100).format('$0,0[.]00')}
                            {' '}
                            total
                          </Text>
                        </Skeleton>
                        <Skeleton loading={loading}>
                          <Text size="2" color="gray">
                            {numeral((credits.credits - credits.costs) / 100).format('$0,0[.]00')}
                            {' '}
                            remaining
                          </Text>
                        </Skeleton>
                      </Flex>
                    </Flex>
                    <Skeleton loading={loading}>
                      <Flex direction="column" gap="2">
                        <Flex gap="3">
                          <Text size="2" color="gray">Charges</Text>
                          <Text size="2" className="flex-1 text-right">{numeral(credits.charges / 100).format('$0,0[.]00')}</Text>
                        </Flex>
                        <Separator size="4" />
                        <Flex gap="3">
                          <Text size="2" color="gray">QPM</Text>
                          <Text size="2" className="flex-1 text-right">{numeral(credits.qpm).format('0,0[.]00')}</Text>
                        </Flex>
                      </Flex>
                    </Skeleton>
                  </Flex>
                </Card>
                <Skeleton loading={loading}>
                  <Card className="min-h-56 flex-1">
                    <Flex gap="4" direction="column">
                      <ChartContainer
                        className="h-[150px]"
                        config={{
                          costs: {
                            label: 'Costs',
                            color: 'var(--accent-9)',
                          },
                        }}
                      >
                        <BarChart accessibilityLayer data={monthlySnapshot}>
                          <YAxis max={credits.costs} hide />
                          <ChartTooltip
                            cursor={false}
                            formatter={(value, _name, _item, index) => {
                              const xName = monthlySnapshot[index].month
                              return `${xName}: ${numeral(value as number / 100).format('$0,0[.]00')}`
                            }}
                            content={(<ChartTooltipContent indicator="dashed" />)}
                          />
                          <Bar dataKey="costs" fill="var(--color-costs)" radius={4} barSize={30} />
                        </BarChart>
                      </ChartContainer>
                      <Flex direction="column" gap="2">
                        <Text size="3">Monthly snapshot</Text>
                      </Flex>
                    </Flex>
                  </Card>
                </Skeleton>
              </Flex>
            </Flex>
          </Card>
          <TokenUsage />
        </Flex>
      </Box>
    </Content>
  )
}
