import { Link } from 'react-router-dom'
import { Box, Flex, Grid, Text } from '@radix-ui/themes'
import { Helmet } from 'react-helmet-async'
// import { UpdateTeamAvatar } from '~/views/team/UpdateTeamAvatar.tsx'
import { UpdateTeamName } from '~/views/team/UpdateTeamName.tsx'
import { Content } from '~/views/layout/Content.tsx'
import { ManageMember } from '~/views/team/ManageMember'

export default function TeamSetting() {
  return (
    <Content title="Team Settings" row>
      <Helmet>
        <title>
          Team Settings
        </title>
      </Helmet>

      <Box width="15rem">
        <Grid gap="4">
          <Link to="#">
            <Text size="3" weight="medium">General</Text>
          </Link>
        </Grid>
      </Box>
      <Flex direction="column" gap="6" className="flex-1">
        <Flex direction="column" gap="6" className="flex-1">
          <UpdateTeamName />
          {/* <UpdateTeamAvatar /> */}
          <ManageMember />
        </Flex>
      </Flex>
    </Content>
  )
}
