import { Avatar } from '@radix-ui/themes'
import useSWR from 'swr'

export function UserAvatar({ userId, size }: { userId: string, size: any }) {
  const { data, error, isLoading } = useSWR(`/users/${userId}`)

  if (isLoading)
    return null

  if (error)
    return null

  return <Avatar size={size} src={data.data.avatar} fallback="U" />
}
