import { Button, Flex, Select, TextArea } from '@radix-ui/themes'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'
import { setCurrentProject, updateAiPrompt, useProjectStore } from '~/stores/project'

export function UpdatePrompt({ setTempPrompt, clearChatMessages }: {
  setTempPrompt?: Dispatch<SetStateAction<string>>
  clearChatMessages?: () => void
}) {
  const projectStore = useProjectStore()
  const [prompt, setPrompt] = useState(projectStore.currentProject.aiPrompt)
  const [submitting, setSubmitting] = useState(false)
  const [promptOperation, setPromtOperation] = useState<string>()

  const currentProject = projectStore.currentProject

  useEffect(() => {
    setPrompt(currentProject.aiPrompt)
  }, [currentProject.id, currentProject.aiPrompt])

  useEffect(() => {
    setPromtOperation(localStorage.getItem(`PROMPT_OPERATION_${currentProject.id}`) || '0')
  }, [currentProject.id])

  useEffect(() => {
    setTempPrompt?.(prompt)
  }, [prompt, setTempPrompt])

  async function updatePrompt() {
    setSubmitting(true)
    try {
      const ok = await updateAiPrompt(currentProject.teamId, currentProject.id, prompt)
      if (ok) {
        setCurrentProject({
          ...currentProject,
          aiPrompt: prompt,
        })
        toast.success('Prompt updated successfully')
      }
      else {
        toast.error('Failed to update prompt')
      }
    }
    catch (error) {
      toast.error('Failed to update prompt')
    }
    finally {
      setSubmitting(false)
    }
  }

  const onPromptOperationChange = (value: string) => {
    localStorage.setItem(`PROMPT_OPERATION_${currentProject.id}`, value)
    // clear ai chat history
    clearChatMessages?.()
    localStorage.removeItem(`AI_CHAT_HISTORY_${currentProject.id}`)
    setPromtOperation(value)
  }

  return (
    <div className="flex-1">
      <Flex direction="column" gap="4">
        <TextArea
          variant="soft"
          color="gray"
          size="3"
          placeholder="Write your prompt here"
          value={prompt}
          onChange={e => setPrompt(e.target.value)}
          resize="vertical"
          className="min-h-80 p-1"
        >
          {prompt}
        </TextArea>
        <Flex align="center" justify="between">
          <Flex align="center" gap="4" flexGrow="1">
            <Button variant="ghost">Tutorials</Button>
            <Button variant="ghost">Docs</Button>
          </Flex>
          <Flex align="center" gap="4" flexGrow="0">
            <Select.Root size="2" value={promptOperation} onValueChange={value => onPromptOperationChange(value)}>
              <Select.Trigger />
              <Select.Content>
                <Select.Item value="0">Overwrite</Select.Item>
                <Select.Item value="2">Prefix</Select.Item>
                <Select.Item value="1">Disabled</Select.Item>
              </Select.Content>
            </Select.Root>
            <Button loading={submitting} onClick={updatePrompt}>Save</Button>
          </Flex>
        </Flex>
      </Flex>
    </div>
  )
}
