import React from 'react'

export function TokenOutIcon(props: React.SVGProps<SVGSVGElement>) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = fill

  return (
    <svg height="1em" width="1em" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <line fill="none" stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="9" x2="9" y1="6" y2="17" />
        <polyline fill="none" points="11.25 15 9 17.25 6.75 15" stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path d="M6.5,12.059l-3.837-2.02c-.267-.141-.413-.402-.413-.664v-3.25" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path d="M11.507,8.807l3.843-2.019c.535-.281,.535-1.046,0-1.327l-5.883-3.091c-.146-.077-.307-.116-.467-.116-.16,0-.321,.038-.467,.116L2.651,5.461c-.535,.281-.535,1.046,0,1.327l3.843,2.019" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path d="M11.502,12.059l3.837-2.02c.267-.141,.413-.402,.413-.664v-3.25" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </svg>
  )
}
