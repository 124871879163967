import { Button, Card, Flex, Inset, Separator, Text, TextField } from '@radix-ui/themes'
import { useState } from 'react'
import { toast } from 'sonner'
import { UserAvatar } from '../user/Avatar'
import { UserName } from '../user/UserName'

import { apiFetch } from '~/libs/fetcher'
import { useTeamMembers, useTeamStore } from '~/stores/team'

export function ManageMember() {
  const teamStore = useTeamStore()
  const { members, error, isLoading, mutate } = useTeamMembers(teamStore.currentTeam.id)
  const [email, setEmail] = useState('')

  async function handleAddMember() {
    const res = await apiFetch(`/teams/${teamStore.currentTeam.id}/members/add`, {
      method: 'POST',
      body: { email },
      onResponseError() {
        toast.error('Failed to add member')
      },
    })

    if (res.ok)
      toast.success('Member added')
    else
      toast.error('Failed to add member')

    mutate()
  }

  async function handleRemoveMember(userId: string) {
    const res = await apiFetch(`/teams/${teamStore.currentTeam.id}/members/${userId}`, {
      method: 'DELETE',
      onResponseError() {
        toast.error('Failed to remove member')
      },
    })

    if (res.ok)
      toast.success('Member removed')
    else
      toast.error('Failed to remove member')

    mutate()
  }

  if (isLoading)
    return null

  if (error) {
    return (
      <Card size="3">
        <Flex direction="column" gap="4">
          <Text size="5" weight="bold">Team Members</Text>
          <Text size="2" className="text-[--accent-9]">Failed to fetch team members</Text>
        </Flex>
      </Card>
    )
  }

  return (
    <Card size="3">
      <Flex direction="column" gap="4">
        <Flex direction="column" gap="1">
          <Text size="5" weight="bold">Team Members</Text>
          <Text size="2" className="text-[--accent-9]"> Add or remove team members </Text>
        </Flex>
      </Flex>
      <Inset my="6">
        <Separator size="4" />
      </Inset>
      <Flex className="py-4" gap="3" direction="column">
        {members.map(member => (
          <Flex key={member.id} gap="2" flexGrow="1" align="center">
            <Flex flexGrow="1" align="center" gap="3" className="select-none">
              <UserAvatar userId={member.userId} size="3" />
              <UserName userId={member.userId} />
              <Text size="3"></Text>
            </Flex>

            <Button size="1" variant="ghost" className="mr-1" onClick={() => handleRemoveMember(member.userId)}>Remove</Button>
          </Flex>
        ))}
      </Flex>
      <Inset my="6">
        <Separator size="4" />
      </Inset>
      <Flex className="gap-3 pt-4">
        <TextField.Root size="3" className="w-full" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
        <Button size="3" onClick={handleAddMember}>Add</Button>
      </Flex>
    </Card>
  )
}
