import { Card, Flex, Skeleton, Text } from '@radix-ui/themes'
import { useProjectStore, useTokenUsage } from '~/stores/project'
import { TokenOutIcon } from '~/components/icons/TokenOutIcon.tsx'
import { TokenInIcon } from '~/components/icons/TokenInIcon.tsx'

const formatter = Intl.NumberFormat('en', { notation: 'compact' })

export function TokenUsage() {
  const projectStore = useProjectStore()

  const { usage, isLoading, error } = useTokenUsage(projectStore.currentProject.teamId, projectStore.currentProject.id)

  if (error)
    return <div>Failed to fetch functions</div>

  if (isLoading) {
    return (
      <>
        <Card size="4"><Skeleton /></Card>
        <Card size="4"><Skeleton /></Card>
      </>
    )
  }

  return (
    <>
      <Card size="4">
        <Flex justify="between" align="center">
          <Flex direction="column" gap="2">
            <Text size="2" color="gray">Input tokens</Text>
            <Text size="8" weight="bold">{formatter.format(usage.input_tokens)}</Text>
          </Flex>
          <TokenOutIcon className="size-12 text-[--gray-9]" />
        </Flex>
      </Card>
      <Card size="4">
        <Flex justify="between" align="center">
          <Flex direction="column" gap="2">
            <Text size="2" color="gray">Output tokens</Text>
            <Text size="8" weight="bold">{formatter.format(usage.output_tokens)}</Text>
          </Flex>
          <TokenInIcon className="size-12 text-[--gray-9]" />
        </Flex>
      </Card>
    </>
  )
}
