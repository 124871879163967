import React from 'react'

export function TokenInIcon(props: React.SVGProps<SVGSVGElement>) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = fill

  return (
    <svg height="1em" width="1em" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <line fill="none" stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="9" x2="9" y1="8.25" y2="1" />
        <line fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="8.999" x2="8.999" y1="15.745" y2="12.494" />
        <polyline fill="none" points="6.75 3 9 .75 11.25 3" stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path d="M6.495,5.942l-3.843,2.019c-.535,.281-.535,1.046,0,1.327l5.883,3.091c.293,.154,.641,.154,.934,0l5.883-3.091c.535-.281,.535-1.046,0-1.327l-3.845-2.021" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path d="M15.748,8.624v3.251c0,.262-.146,.523-.413,.664l-5.87,3.091c-.146,.077-.306,.116-.466,.116-.16,0-.32-.038-.466-.116l-5.87-3.091c-.267-.141-.413-.402-.413-.664v-3.251" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </svg>
  )
}
