import { Button, Dialog, Flex, Spinner, Text, TextField } from '@radix-ui/themes'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'sonner'
import { config } from '~/config'
import { apiBillingFetch } from '~/libs/fetcher'
import { useTeamStore } from '~/stores/team'

export function UpgradeModal({ isOpen, onOpenChange, plan }: {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  plan: Record<string, any>
}) {
  const teamStore = useTeamStore()
  const [step, setStep] = useState(0)
  const [initializing, setInitializing] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogDescription, setDialogDescription] = useState('')
  const [customer, setCustomer] = useState<Record<string, any>>({
    name: '',
    email: '',
  })
  const [customerCreating, setCustomerCreating] = useState(false)
  const [checkoutSessionsLoading, setCheckoutSessionsLoading] = useState(false)
  const [, setCheckoutSessions] = useState<Record<string, any>>({})
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    return () => {
      if (timerRef.current)
        clearTimeout(timerRef.current)
    }
  }, [])

  useEffect(() => {
    switch (step) {
      case 0:
        setDialogTitle('Waiting...')
        setDialogDescription('')
        break
      case 1:
        setDialogTitle('Update team details')
        break
      case 2:
        setDialogTitle(`Upgrade to ${plan.name}`)
        break
      default:
        break
    }
  }, [step, plan])

  const getCheckoutSessions = async (customerId: string) => {
    setCheckoutSessionsLoading(true)
    try {
      const res = await apiBillingFetch('/v1/checkout/sessions', {
        method: 'POST',
        body: {
          success_url: `${window.location.origin}/payment/result`,
          cancel_url: `${window.location.origin}/payment/result`,
          mode: 'subscription',
          customer_id: customerId,
          items: [
            {
              price_id: config.stripe.priceId.growth,
              quantity: 1,
            },
          ],
        },
      })
      if (!res.data)
        return toast.error('Failed load checkout sessions')
      setCheckoutSessions(res.data)
      const width = 600
      const height = 1000

      const paymentWindow = window.open(res.data.url, '_blank', `width=${width},height=${height}`)
      if (!paymentWindow)
        return toast.info('Payment window blocked!')
      if (timerRef.current)
        clearTimeout(timerRef.current)
      timerRef.current = setInterval(() => {
        if (paymentWindow?.closed) {
          if (timerRef.current)
            clearTimeout(timerRef.current)
          window.location.reload()
        }
      }, 200)
      onOpenChange(false)
    }
    finally {
      setCheckoutSessionsLoading(false)
    }
  }

  const fetchCustomerInfo = async () => {
    if (!teamStore.currentTeam?.id || !isOpen)
      return
    setInitializing(true)
    try {
      const res = await apiBillingFetch(`/v1/customers/${teamStore.currentTeam.id}`)
      if (res.data) {
        setStep(2)
        getCheckoutSessions(res.data?.id)
      }
      else {
        setStep(1)
      }
    }
    catch (error) {
      setStep(1)
    }
    finally {
      setInitializing(false)
    }
  }

  useEffect(() => {
    fetchCustomerInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamStore.currentTeam.id, isOpen])

  const handleCreateCustomer = async () => {
    const name = customer.name.trim()
    const email = customer.email.trim()
    if (!name)
      return toast.error('Name is required')
    if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))
      return toast.error('Invalid email')
    setCustomerCreating(true)
    try {
      const res = await apiBillingFetch('/v1/customers', {
        method: 'POST',
        body: {
          org_id: teamStore.currentTeam.id,
          name,
          email,
        },
      })
      if (res.data) {
        setStep(2)
        getCheckoutSessions(res.data?.id)
      }
    }
    finally {
      setCustomerCreating(false)
    }
  }

  const handleCloseAutoFocus = () => {
    setCustomer({
      name: '',
      email: '',
    })
    setStep(0)
  }
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content maxWidth="450px" onCloseAutoFocus={handleCloseAutoFocus}>
        <Dialog.Title>{dialogTitle}</Dialog.Title>
        {
          dialogDescription && <Dialog.Description mb="4">{dialogDescription}</Dialog.Description>
        }
        {
          initializing && <div className="flex h-[50px] items-center justify-center"><Spinner /></div>
        }
        {
          step === 1 && (
            <Flex direction="column" gap="3">
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  Name
                </Text>
                <TextField.Root
                  value={customer.name}
                  placeholder="Acme Inc."
                  onChange={e => setCustomer({ ...customer, name: e.target.value })}
                />
              </label>
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  Email
                </Text>
                <TextField.Root
                  value={customer.email}
                  placeholder="invoices@business.com"
                  onChange={e => setCustomer({ ...customer, email: e.target.value })}
                />
              </label>
            </Flex>
          )
        }
        {
          step === 2 && checkoutSessionsLoading && <div className="flex h-[50px] items-center justify-center"><Spinner /></div>
        }
        {
          !initializing && (
            <Flex gap="3" mt="4" justify="end">
              <Dialog.Close>
                <Button variant="soft" color="gray">
                  Cancel
                </Button>
              </Dialog.Close>
              {step === 1 && <Button onClick={handleCreateCustomer} loading={customerCreating}>Continue to Payment</Button>}
            </Flex>
          )
        }
      </Dialog.Content>
    </Dialog.Root>
  )
}
