import { useEffect, useMemo, useState } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Button, Spinner, Table, Text } from '@radix-ui/themes'
import { toast } from 'sonner'
import { format } from 'date-fns'
import { ProjectLogStatusCode } from './ProjectLogStatusCode.tsx'
import { apiFetch } from '~/libs/fetcher.ts'
import { useProjectStore } from '~/stores/project.ts'

interface ProjectLogResponse {
  output: [
    {
      records: {
        schema: { column_schemas: { name: string, data_type: string }[] }
        rows: (string | number)[][]
        total_rows: number
        metrics: { greptime_cloud_rcu: number }
      }
    },
  ]
  execution_time_ms: number
}

export interface ProjectLog {
  ts: string
  app_id: string
  mesh_zone: string
  remote_ip: string
  remote_port: number
  uri: string
  referer: string
  user_agent: string
  status_code: number
  body_bytes: number
}

export function ProjectLogTable({ pageSize = 10 }: { pageSize?: number }) {
  const projectStore = useProjectStore()
  const [logResponse, setLogResponse] = useState<ProjectLogResponse | null>(null)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  const getProjectLogData = async (offset?: number) => {
    setIsLoading(true)
    try {
      const response = await apiFetch(
          `/teams/${projectStore.currentProject.teamId}/projects/${projectStore.currentProject.id}/log`,
          {
            method: 'POST',
            body: {
              sql: `
              SELECT * FROM "access_log" WHERE app_id='${projectStore.currentProject.id}'
              ORDER BY ts DESC LIMIT ${pageSize} ${offset ? `OFFSET ${offset}` : ''}
            `,
            },
          },
      )
      if (!response.ok || response.data.Error) {
        toast.error('Failed to fetch project log')
        return
      }

      const totalRowsResponse = await apiFetch(
          `/teams/${projectStore.currentProject.teamId}/projects/${projectStore.currentProject.id}/log`,
          {
            method: 'POST',
            body: {
              sql: `SELECT COUNT(1) as total_rows FROM "access_log" WHERE app_id='${projectStore.currentProject.id}'`,
            },
          },
      )

      if (!totalRowsResponse.ok || totalRowsResponse.data.Error) {
        toast.error('Failed to fetch total rows')
        return
      }
      setTotalRows(totalRowsResponse.data.output[0].records.rows[0][0])
      setLogResponse(response.data)
    }
    catch (error) {
      console.error(error)
      toast.error('Failed to fetch project log')
    }
    finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getProjectLogData()
  }, [pageSize, projectStore.currentProject])

  const columnHelper = createColumnHelper<ProjectLog>()

  const columnSize: Record<string, number> = {
    ts: 200,
    app_id: 270,
    mesh_zone: 140,
    remote_ip: 140,
    remote_port: 140,
    uri: 200,
    referer: 200,
    user_agent: 200,
    status_code: 140,
    body_bytes: 140,
  }

  const columns = useMemo(() => {
    return logResponse
      ? logResponse.output[0].records.schema.column_schemas.map((column: any) =>
        columnHelper.accessor(column.name, {
          header: column.name.toUpperCase(),
          size: columnSize[column.name],
        }),
      )
      : []
  }, [logResponse])

  const rowData = useMemo(() => {
    return logResponse
      ? logResponse.output[0].records.rows.map((row: any[]) => ({
        ts: row[0],
        app_id: row[1],
        mesh_zone: row[2],
        remote_ip: row[3],
        remote_port: row[4],
        uri: row[5],
        referer: row[6],
        user_agent: row[7],
        status_code: row[8],
        body_bytes: row[9],
      }))
      : []
  }, [logResponse])

  const table = useReactTable({
    data: rowData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageIndex: 0, pageSize } },
    pageCount: Math.ceil(totalRows / pageSize),
  })

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
    const offset = (newPage - 1) * pageSize
    getProjectLogData(offset)
  }

  return isLoading
    ? (
      <div className="flex h-full items-center justify-center p-4">
        <Spinner size="3" />
      </div>
      )
    : (
      <div className="flex h-full flex-col">
        <ProjectLogStatusCode logRows={rowData} />
        <Table.Root className="h-0 flex-1" size="1">
          <Table.Header>
            {table.getHeaderGroups().map(headerGroup => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <Table.ColumnHeaderCell key={header.id} style={{ minWidth: header.getSize() }}>
                    {!header.isPlaceholder
                    && flexRender(header.column.columnDef.header, header.getContext())}
                  </Table.ColumnHeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body>
            {table.getRowModel().rows.map(row => (
              <Table.Row key={row.id} className={row.original.status_code === 429 ? 'text-red-500' : ''}>
                {row.getVisibleCells().map(cell => (
                  <Table.Cell key={cell.id} style={{ minWidth: cell.column.getSize() }}>
                    {cell.column.id === 'ts'
                      ? flexRender(format(cell.row.original.ts, 'yyyy-MM-dd HH:mm:ss'), cell.getContext())
                      : flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
        <div className="flex items-center justify-end gap-4 p-2 text-sm">
          Total rows:
          {' '}
          {totalRows}
          <Text>
            {currentPage}
            {' '}
            /
            {' '}
            {table.getPageCount()}
          </Text>
          <Button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>Prev</Button>
          <Button disabled={currentPage === table.getPageCount()} onClick={() => handlePageChange(currentPage + 1)}>Next</Button>
        </div>
      </div>
      )
}
