import { Cross2Icon } from '@radix-ui/react-icons'
import { Badge, Button, Card, Flex, Heading, Separator, Skeleton, Text } from '@radix-ui/themes'
import { CheckIcon } from 'lucide-react'

export function PlanCard({
  icon: Icon,
  title,
  description,
  price,
  period,
  features,
  limitations,
  buttonText,
  buttonVariant,
  highlighted = false,
  loading,
  current,
  handleUpgrade,
}: {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  title: string
  description: string
  price: string
  period: string
  features: string[]
  limitations?: string[]
  buttonText: string
  buttonVariant: 'outline' | 'solid'
  highlighted?: boolean
  loading?: boolean
  current?: boolean
  handleUpgrade: () => void
}) {
  return (
    <Card
      size="3"
      className={`flex w-full max-w-[400px] flex-col ${
        highlighted
          ? 'shadow-[0_0_20px_4px_var(--green-9)] ring-1 ring-green-600'
          : ''
      }`}
    >
      <Flex direction="column" gap="5" flexGrow="1">
        <Flex align="center" gap="2" justify="between">
          <Flex align="center" gap="2">
            <Skeleton loading={loading}>
              <Icon className={highlighted ? 'text-green-600' : 'text-gray-500'} />
            </Skeleton>
            <Skeleton loading={loading}>
              <Heading as="h2" size="6">
                {title}
              </Heading>
            </Skeleton>
          </Flex>
          {highlighted && (
            <Skeleton loading={loading}>
              <Badge size="1" color="green">Most Popular</Badge>
            </Skeleton>
          )}
        </Flex>
        <Flex>
          <Skeleton loading={loading}><Text as="p" color="gray" size="2">{description}</Text></Skeleton>
        </Flex>
        <Flex direction="column" gap="2">
          <Skeleton loading={loading}>
            <Text size="8" weight="bold">
              {price}
              <Text size="2" color="gray" as="span">
                {' '}
                {period}
              </Text>
            </Text>
          </Skeleton>
        </Flex>
        <Flex>
          <Skeleton loading={loading}><Separator size="4" /></Skeleton>
        </Flex>
        <Flex direction="column" gap="2" flexGrow="1">
          {features.map(feature => (
            <Flex key={feature} align="start" gap="2">
              <Skeleton loading={loading}>
                <div className="shrink-0 grow-0">
                  <CheckIcon className="text-green-500" width={24} height={24} />
                </div>
              </Skeleton>
              <Skeleton loading={loading}><Text size="2">{feature}</Text></Skeleton>
            </Flex>
          ))}
          {limitations && limitations.map(limitation => (
            <Flex key={limitation} align="start" gap="2">
              <Skeleton loading={loading}>
                <div className="shrink-0 grow-0">
                  <Cross2Icon className="text-red-500" width={24} height={24} />
                </div>
              </Skeleton>
              <Skeleton loading={loading}><Text size="2" color="gray">{limitation}</Text></Skeleton>
            </Flex>
          ))}
        </Flex>
        <Flex>
          <Skeleton loading={loading}>
            <Button
              size="3"
              variant={highlighted ? 'solid' : buttonVariant}
              color={highlighted ? 'green' : undefined}
              disabled={current}
              className="flex-1"
              onClick={handleUpgrade}
            >
              {current ? 'Current Plan' : buttonText}
            </Button>
          </Skeleton>
        </Flex>
      </Flex>
    </Card>
  )
}
