import { Avatar, Box, Card, Flex, Inset, Separator, Text } from '@radix-ui/themes'
import { useUserStore } from '~/stores/user'

export function UpdateAvatar() {
  const userStore = useUserStore()

  return (
    <Card size="3">
      <Flex direction="column" gap="3">
        <Flex align="center">
          <Flex direction="column" className="flex-1" gap="1">
            <Text size="5" weight="bold">Your Avatar</Text>
            <Text size="2" className="text-[--accent-9]">
              Click on the avatar to upload a custom one from your files.
            </Text>
          </Flex>
          <Box>
            <Avatar src={userStore.me.avatar} fallback={userStore.me.name[0]} size="5" radius="full" className="cursor-pointer" />
          </Box>
        </Flex>
        <Inset my="6">
          <Separator size="4" />
        </Inset>
        <Text size="2" className="text-[--gray-9]">
          An avatar is optional but strongly recommended.
        </Text>
      </Flex>
    </Card>
  )
}
