import { CopyIcon } from '@radix-ui/react-icons'
import { Card, Code, DataList, Flex, Heading, IconButton, Inset, Section, Text } from '@radix-ui/themes'
import ReactPrismjs from '@uiw/react-prismjs'
import copy from 'copy-text-to-clipboard'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { toast } from 'sonner'
import { useProjectStore } from '~/stores/project.ts'
import { Content } from '~/views/layout/Content.tsx'
import { UpdatePrompt } from '~/views/project/UpdatePrompt.tsx'

import 'prismjs/components/prism-bash'
import { AiChat } from '~/views/project/AiChat'

const BASE_URL = 'https://api.vivgrid.com/v1'

export function ProjectBridge() {
  const chatRef = useRef<{
    clearMessages: () => void
  }>(null)
  const [showSecret, setShowSecret] = useState(false)
  const [tempPrompt, setTempPrompt] = useState<string>('')
  const projectStore = useProjectStore()

  const currentProject = projectStore.currentProject
  const token = `${currentProject.appKey}.${currentProject.appSecret}`

  useEffect(() => {
    setTempPrompt(currentProject.aiPrompt)
  }, [currentProject.id, currentProject.aiPrompt])

  function getCodeStr(showSecret = false) {
    const codeStr = `curl https://api.vivgrid.com/v1/chat/completions \\\r
  -H "Content-Type: application/json" \\\r
  -H "Authorization: Bearer ${currentProject.appKey}.${showSecret ? currentProject.appSecret : currentProject.appSecret.split('').map(_ => '*').join('')}" \\\r
  -d '{
    "model": "gpt-4o",
    "messages": [{"role": "user", "content": "once upon a time there was a lovely blue dinosaur"}],
    "temperature": 0.7,
    "stream": true
  }'`
    return codeStr
  }

  function copyText(text: string) {
    const success = copy(text)
    if (success)
      toast.success('Copied to clipboard')
    else
      toast.error('Failed to copy to clipboard')
  }

  const clearChatMessages = () => {
    chatRef.current?.clearMessages()
  }

  return (
    <Content title="AI Bridge">
      <Helmet>
        <title>
          AI Bridge /
          {' '}
          {projectStore.currentProject.name}
        </title>
      </Helmet>

      <Section size="2" pt="0">
        <Flex direction="column" gap="4">
          <Flex direction="column" gap="1">
            <Heading size="5">System Prompt</Heading>
            <Text size="2" color="gray">
              Overwrite the System Prompt on the fly
            </Text>
          </Flex>
          <Flex>
            <UpdatePrompt setTempPrompt={setTempPrompt} clearChatMessages={clearChatMessages} />
            <AiChat token={token} projectId={currentProject.id} tempPrompt={tempPrompt} ref={chatRef} />
          </Flex>
        </Flex>
      </Section>

      <Section size="2" pt="0">
        <Flex direction="column" gap="4">
          <Flex direction="column" gap="1">
            <Heading size="5">Endpoint</Heading>
            <Text size="2" color="gray">
              Try our AI Bridge API in your terminal:
            </Text>
          </Flex>
          <Card>
            <DataList.Root>
              <DataList.Item>
                <DataList.Label minWidth="88px">BASE_URL</DataList.Label>
                <DataList.Value>
                  <div className="flex items-center gap-2">
                    <Code variant="ghost" className="select-all" onDoubleClick={() => { copyText(BASE_URL) }}>{BASE_URL}</Code>
                    <IconButton
                      size="1"
                      aria-label="Copy value"
                      color="gray"
                      variant="ghost"
                      onClick={() => {
                        copyText(BASE_URL)
                      }}
                    >
                      <CopyIcon />
                    </IconButton>
                  </div>
                </DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label minWidth="88px">TOKEN</DataList.Label>
                <DataList.Value>
                  <div className="flex items-center gap-2">
                    <Code
                      variant="ghost"
                      className="select-all"
                      onDoubleClick={() => { showSecret && copyText(`${currentProject.appKey}.${currentProject.appSecret}`) }}
                    >
                      {showSecret ? `${currentProject.appKey}.${currentProject.appSecret}` : `${currentProject.appKey}.${currentProject.appSecret.split('').map(_ => '*').join('')}`}
                    </Code>

                    <IconButton
                      size="1"
                      aria-label="show value"
                      color="gray"
                      variant="ghost"
                      onClick={() => {
                        setShowSecret(!showSecret)
                      }}
                    >
                      {showSecret ? <EyeIcon size={18} strokeWidth={1.25} /> : <EyeOffIcon size={18} strokeWidth={1.25} />}
                    </IconButton>

                    <IconButton
                      size="1"
                      aria-label="Copy value"
                      color="gray"
                      variant="ghost"
                      onClick={() => {
                        copyText(`${currentProject.appKey}.${currentProject.appSecret}`)
                      }}
                    >
                      <CopyIcon />
                    </IconButton>
                  </div>
                </DataList.Value>
              </DataList.Item>
            </DataList.Root>
          </Card>
          <Card className="relative">
            <Inset>
              <ReactPrismjs className="!whitespace-pre-wrap !text-lg" source={getCodeStr(false)} language="bash" />
            </Inset>
            <IconButton
              className="absolute right-2 top-2"
              size="1"
              aria-label="Copy value"
              color="gray"
              variant="ghost"
              onClick={() => {
                copyText(getCodeStr(true))
              }}
            >
              <CopyIcon />
            </IconButton>
          </Card>
        </Flex>
      </Section>
    </Content>
  )
}
