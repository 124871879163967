import React from 'react'

export function ServerIcon(props: React.SVGProps<SVGSVGElement>) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = fill

  return (
    <svg height="1em" width="1em" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <rect height="4" width="10.5" fill="none" rx="1.5" ry="1.5" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x=".75" y=".75" />
        <rect height="4" width="10.5" fill="none" rx="1.5" ry="1.5" stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x=".75" y="7.25" />
        <circle cx="2.75" cy="2.75" fill={fill} r=".75" strokeWidth="1.5" />
        <circle cx="4.75" cy="2.75" fill={fill} r=".75" strokeWidth="1.5" />
        <circle cx="2.75" cy="9.25" fill={secondaryfill} r=".75" strokeWidth="1.5" />
        <circle cx="4.75" cy="9.25" fill={secondaryfill} r=".75" strokeWidth="1.5" />
      </g>
    </svg>
  )
}
