import React from 'react'

export function FunctionIcon(props: React.SVGProps<SVGSVGElement>) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = fill

  return (
    <svg height="1em" width="1em" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M9 6.25C10.2426 6.25 11.25 5.24264 11.25 4C11.25 2.75736 10.2426 1.75 9 1.75C7.75736 1.75 6.75 2.75736 6.75 4C6.75 5.24264 7.75736 6.25 9 6.25Z" fill={secondaryfill} fillOpacity="0.3" stroke="none" />
        <path d="M14 15.75C15.2426 15.75 16.25 14.7426 16.25 13.5C16.25 12.2574 15.2426 11.25 14 11.25C12.7574 11.25 11.75 12.2574 11.75 13.5C11.75 14.7426 12.7574 15.75 14 15.75Z" fill={secondaryfill} fillOpacity="0.3" stroke="none" />
        <path d="M4 15.75C5.24264 15.75 6.25 14.7426 6.25 13.5C6.25 12.2574 5.24264 11.25 4 11.25C2.75736 11.25 1.75 12.2574 1.75 13.5C1.75 14.7426 2.75736 15.75 4 15.75Z" fill={secondaryfill} fillOpacity="0.3" stroke="none" />
        <path d="M9 6.25V9.75" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path d="M5.84302 12.21L9.00002 9.75" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path d="M12.157 12.21L9 9.75" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path d="M9 6.25C10.2426 6.25 11.25 5.24264 11.25 4C11.25 2.75736 10.2426 1.75 9 1.75C7.75736 1.75 6.75 2.75736 6.75 4C6.75 5.24264 7.75736 6.25 9 6.25Z" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path d="M14 15.75C15.2426 15.75 16.25 14.7426 16.25 13.5C16.25 12.2574 15.2426 11.25 14 11.25C12.7574 11.25 11.75 12.2574 11.75 13.5C11.75 14.7426 12.7574 15.75 14 15.75Z" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path d="M4 15.75C5.24264 15.75 6.25 14.7426 6.25 13.5C6.25 12.2574 5.24264 11.25 4 11.25C2.75736 11.25 1.75 12.2574 1.75 13.5C1.75 14.7426 2.75736 15.75 4 15.75Z" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </svg>
  )
}
