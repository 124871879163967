import { Flex, Inset } from '@radix-ui/themes'
import { Helmet } from 'react-helmet-async'
import { Content } from '~/views/layout/Content.tsx'

export function TeamLLM() {
  return (
    <Content title="Whisper">
      <Helmet>
        <title>
          Geo GPU
        </title>
      </Helmet>

      <Inset clip="padding-box" mx="-6" my="-6">
        <Flex className="w-full">
          <iframe
            src="https://edge-ai.yomo.run/"
            className="h-[600px] w-full"
            allow="microphone; cross-origin-isolated"
          >
          </iframe>
        </Flex>
      </Inset>
    </Content>
  )
}
