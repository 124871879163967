import { Badge, Box, Flex, Skeleton, Text } from '@radix-ui/themes'
import { AIIcon } from '~/components/icons/AIIcon.tsx'
import { CloudIcon } from '~/components/icons/CloudIcon.tsx'
import { FunctionIcon } from '~/components/icons/FunctionIcon.tsx'
import { ServerIcon } from '~/components/icons/ServerIcon.tsx'
import { cn } from '~/libs/utils.ts'
import { useFunctions, useProjectStore } from '~/stores/project'

export function ServerlessFunctions() {
  const projectStore = useProjectStore()

  const { functions, isLoading, error } = useFunctions(projectStore.currentProject.teamId, projectStore.currentProject.id)

  if (error)
    return <div>Failed to fetch functions</div>

  if (isLoading) {
    return (
      <Flex direction="column" gap="3">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Flex>
    )
  }

  return (
    <Flex direction="column" gap="3">
      {functions.length === 0 && <Text className="opacity-45">No functions found</Text>}
      {functions.map((fn, idx) => (
        <Flex key={fn.sfn_name} align="stretch" gap="2" pb={idx !== functions.length - 1 ? '3' : '0'} className="relative">
          {idx !== functions.length - 1 && <Box className="GridLine GridLine--Y absolute bottom-0 left-0 w-full" />}

          <Flex direction="column" gap="1" className="relative flex-1">
            <Flex align="center" gap="3">
              <Box className={cn('size-2 rounded-full', fn.running ? 'bg-[--green-a9]' : 'bg-[--red-a9]')} />
              <Text size="1" className="opacity-65">{fn.running ? 'Running' : 'Stopped'}</Text>
            </Flex>
            <Flex align="center" gap="2" className="opacity-90">
              {fn.ai ? <AIIcon className="size-3" /> : <FunctionIcon className="size-3" />}
              <Text size="1">{fn.ai ? 'Function Calling' : 'Serverless Function'}</Text>
            </Flex>
          </Flex>

          <Flex direction="column" gap="1" pl="4" className="relative flex-1">
            <Flex align="center" gap="2">
              <Text size="2" weight="medium" truncate>{fn.sfn_name}</Text>
            </Flex>
            <Flex align="center" gap="2">
              {fn.hosting === 'hosting' ? <CloudIcon className="size-3 text-[--accent-11]" /> : <ServerIcon className="size-3 text-[--accent-11] opacity-60" />}
              <Text size="1" className="opacity-70">{fn.hosting === 'hosting' ? 'Geo Cloud' : 'Self-managed'}</Text>
            </Flex>
          </Flex>

          <Flex align="center" gap="1" pl="4" className="relative flex-1">
            {Array.isArray(fn.obversed_tag) && fn.obversed_tag.map(id => (
              <Badge key={id}>
                tag=
                {id}
                {' '}
                [0x
                {id.toString(16).padStart(2, '0').toUpperCase()}
                ]
              </Badge>
            ))}
            {fn.ai && <Badge key="ai">AI</Badge>}
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}
