import { Avatar, Box, Card, Flex, Text } from '@radix-ui/themes'
import { useProjectStore } from '~/stores/project'

export function UpdateProjectAvatar() {
  const projectStore = useProjectStore()

  return (
    <Card size="3">
      <Flex direction="column" gap="3">
        <Flex align="center">
          <Flex direction="column" className="flex-1" gap="1">
            <Text size="5" weight="bold">Avatar</Text>
            <Text size="2" className="text-[--accent-9]">
              This is your Project's avatar.
            </Text>
          </Flex>
          <Box>
            <Avatar fallback={projectStore.currentProject.name[0]} size="5" radius="small" className="cursor-pointer" />
          </Box>
        </Flex>
      </Flex>
    </Card>
  )
}
