import { CaretSortIcon, CheckIcon, FileTextIcon } from '@radix-ui/react-icons'
import { Avatar, Badge, Box, Button, DropdownMenu, Flex, Heading, Popover, Text } from '@radix-ui/themes'
import { BarChart } from 'lucide-react'
import { useContext, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '~/components/command'
import { BackIcon } from '~/components/icons/BackIcon.tsx'
import { BillingIcon } from '~/components/icons/BillingIcon.tsx'
import { CogIcon } from '~/components/icons/CogIcon.tsx'
import { ConfigurationIcon } from '~/components/icons/ConfigurationIcon.tsx'
import { CreateTeamIcon } from '~/components/icons/CreateTeamIcon'
import { GridListIcon } from '~/components/icons/GridListIcon.tsx'
import { HexagonsIcon } from '~/components/icons/HexagonsIcon.tsx'
import { LogOutIcon } from '~/components/icons/LogOutIcon.tsx'
import { MicrochipIcon } from '~/components/icons/MicrochipIcon.tsx'
import { ServerlessIcon } from '~/components/icons/ServerlessIcon.tsx'
import { DarkThemeIcon, LightThemeIcon, SystemThemeIcon } from '~/components/icons/ThemeIcons.tsx'
import { UsageIcon } from '~/components/icons/UsageIcon.tsx'
import { UserSettingsIcon } from '~/components/icons/UserSettingsIcon.tsx'
import { ThemeContext } from '~/components/ThemeProvider.tsx'
import { signOut } from '~/libs/logto.ts'
import { cn } from '~/libs/utils.ts'
import { useProjectStore, useProjects } from '~/stores/project.ts'
import { setCurrentTeam, useTeamStore } from '~/stores/team.ts'
import { useUserStore } from '~/stores/user.ts'

interface SidebarProps {
  approved?: boolean
}

export function Sidebar({ approved }: SidebarProps) {
  const params = useParams()
  const userStore = useUserStore()
  const teamStore = useTeamStore()
  const { theme, setTheme, roundness, setRoundness } = useContext(ThemeContext)

  return (
    <aside className="relative isolate flex h-full w-[--sidebar-width] shrink-0 grow-0 flex-col p-4 pl-0">
      <Flex justify="between" align="center" px="2">
        <Link to={`/${teamStore.currentTeam.slug}`}>
          <Flex gap="2" align="center" className="flex-1">
            <img src="/vivgrid.svg" className="size-8" alt="vivgrid Logo" />
            <Heading size="6">
              vivgrid
            </Heading>
          </Flex>
        </Link>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button variant="ghost" radius="full" className="[--button-ghost-padding-x:4px]">
              <Avatar
                src={userStore.me.avatar}
                size="2"
                radius="full"
                fallback={userStore.me.name?.[0]}
              />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content className="min-w-52">
            <DropdownMenu.Label className="my-2">
              <Flex direction="column">
                <Text size="1" weight="medium" className="text-[--accent-12]">{userStore.me.name}</Text>
                <Text size="1">{userStore.me.email}</Text>
              </Flex>
            </DropdownMenu.Label>
            <DropdownMenu.Item disabled={!approved} asChild>
              <Link to="/account" className="flex justify-between">
                Account Settings
                <UserSettingsIcon />
              </Link>
            </DropdownMenu.Item>
            {/* <DropdownMenu.Item disabled className="justify-between">
              Create Team
              <CreateTeamIcon />
            </DropdownMenu.Item> */}
            <DropdownMenu.Separator />
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>
                Appearance
              </DropdownMenu.SubTrigger>
              <DropdownMenu.SubContent>
                <DropdownMenu.Label>
                  Theme
                </DropdownMenu.Label>
                <DropdownMenu.RadioGroup
                  key="theme"
                  value={theme}
                  onValueChange={t => setTheme(t as any)}
                >
                  <DropdownMenu.RadioItem value="system" className="justify-between">
                    System
                    <SystemThemeIcon />
                  </DropdownMenu.RadioItem>
                  <DropdownMenu.RadioItem value="light" className="justify-between">
                    Light
                    <LightThemeIcon />
                  </DropdownMenu.RadioItem>
                  <DropdownMenu.RadioItem value="dark" className="justify-between">
                    Dark
                    <DarkThemeIcon />
                  </DropdownMenu.RadioItem>
                </DropdownMenu.RadioGroup>
                <DropdownMenu.Separator />
                <DropdownMenu.Label>
                  Border Radius
                </DropdownMenu.Label>
                <DropdownMenu.RadioGroup
                  key="roundness"
                  value={roundness}
                  onValueChange={t => setRoundness(t as any)}
                >
                  <DropdownMenu.RadioItem value="none" className="justify-between">
                    None
                    <Box className="size-3.5 rounded-none outline-dashed outline-current" />
                  </DropdownMenu.RadioItem>
                  <DropdownMenu.RadioItem value="small" className="justify-between">
                    Small
                    <Box className="size-3.5 rounded-[2px] outline-dashed outline-current" />
                  </DropdownMenu.RadioItem>
                  <DropdownMenu.RadioItem value="large" className="justify-between">
                    Large
                    <Box className="size-3.5 rounded-[5px] outline-dashed outline-current" />
                  </DropdownMenu.RadioItem>
                </DropdownMenu.RadioGroup>
              </DropdownMenu.SubContent>
            </DropdownMenu.Sub>
            <DropdownMenu.Separator />
            <DropdownMenu.Item color="ruby" onClick={() => signOut()} className="justify-between">
              Log Out
              <LogOutIcon />
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Flex>

      <Box mt="4">
        {params.projectName ? <ProjectSelect teamId={teamStore.currentTeam.id} /> : <TeamSelect />}
        {params.projectName ? <ProjectNavbar /> : <TeamNavbar />}
      </Box>
    </aside>
  )
}

function TeamSelect() {
  const teamStore = useTeamStore()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  function handleTeamChange(teamSlug: string) {
    setCurrentTeam(teamSlug)
    navigate(`/${teamSlug}`)
  }

  function handleCreateTeam() {
    navigate('/create-team')
    setOpen(false)
  }

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger>
        <Button
          variant="soft"
          className="relative w-full items-center justify-between [--base-button-height:2.5rem]"
        >
          <Box className="GridLine GridLine--Y absolute left-8 top-0 w-2/3" />
          <Box className="GridLine GridLine--Y absolute bottom-0 left-4 w-4/5" />
          <Box className="GridLine GridLine--X absolute left-0 top-2 h-4" />
          <Box className="GridLine GridLine--X absolute right-0 top-0 h-2" />
          <Flex gap="2" ml="-1" align="center" maxWidth="90%">
            <Avatar fallback={teamStore.currentTeam.name?.[0]} size="1" radius="medium" variant="solid" />
            <Text truncate className="flex-1">{teamStore.currentTeam.name}</Text>
            {teamStore.allTeamsPlan[teamStore.currentTeam.id]?.name === 'Growth' && <Badge color="green" variant="outline" highContrast radius="large">Growth</Badge>}
          </Flex>
          <CaretSortIcon />
        </Button>
      </Popover.Trigger>
      <Popover.Content className="p-0" side="bottom">
        <Command
          value={teamStore.currentTeam.id}
        >
          <CommandList>
            <CommandGroup>
              {teamStore.allTeams.map(team => (
                <CommandItem
                  key={team.id}
                  value={team.id}
                  onSelect={(id) => {
                    setOpen(false)
                    const team = teamStore.allTeams.find(t => t.id === id)
                    handleTeamChange(team!.slug)
                  }}
                  className="flex items-center gap-2"
                >
                  <CheckIcon
                    className={cn(
                      'h-4 w-4',
                      teamStore.currentTeam.id === team.id ? 'opacity-100' : 'opacity-0',
                    )}
                  />
                  <Text size="2">{team.name}</Text>
                  {teamStore.allTeamsPlan[team.id]?.name === 'Growth' && <Badge color="green" variant="outline" highContrast radius="large">Growth</Badge>}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
        <div className="border-t border-[--accent-5] pb-1 pt-2 text-center">
          <Button variant="ghost" onClick={handleCreateTeam}>
            <CreateTeamIcon />
            <Text size="2">Create Team</Text>
          </Button>
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}

const teamNavIds = ['teamOverview', 'teamUsage', 'teamBilling', 'teamSetting']
type TeamNavId = typeof teamNavIds[number]
const teamNavItems: Record<TeamNavId, {
  title: string
  href: (teamSlug: string) => string
  icon: React.FC<{ className?: string }>
}> = {
  teamOverview: {
    title: 'Projects',
    href(teamSlug: string) {
      return `/${teamSlug}`
    },
    icon: GridListIcon,
  },
  teamLLM: {
    title: 'Geo GPU',
    href(teamSlug: string) {
      return `/${teamSlug}/~/llm`
    },
    icon: MicrochipIcon,
  },
  teamSetting: {
    title: 'Settings',
    href(teamSlug: string) {
      return `/${teamSlug}/~/settings`
    },
    icon: CogIcon,
  },
  teamBilling: {
    title: 'Billing',
    href(teamSlug: string) {
      return `/${teamSlug}/~/billing`
    },
    icon: BillingIcon,
  },
  teamUsage: {
    title: 'Usage',
    href(teamSlug: string) {
      return `/${teamSlug}/~/usage`
    },
    icon: BarChart,
  },
}

function TeamNavbar() {
  const params = useParams()
  const teamStore = useTeamStore()
  let activeTeamNav: TeamNavId | '' = 'teamOverview'

  const pathname = params['*'] || ''

  if (pathname === 'account' || pathname === 'create-team')
    activeTeamNav = ''
  else if (pathname.endsWith('settings'))
    activeTeamNav = 'teamSetting'
  else if (pathname.endsWith('llm'))
    activeTeamNav = 'teamLLM'
  else if (pathname.endsWith('billing'))
    activeTeamNav = 'teamBilling'
  else if (pathname.endsWith('usage'))
    activeTeamNav = 'teamUsage'
  else
    activeTeamNav = 'teamOverview'

  return (
    <Flex gap="2" direction="column" my="4">
      {teamNavIds.map((id) => {
        const Icon = teamNavItems[id].icon
        return (
          <Link
            key={id}
            to={teamNavItems[id].href(teamStore.currentTeam.slug)}
            data-active={activeTeamNav === id}
            className="flex h-10 items-center gap-2 rounded-[--radius-4] bg-transparent px-3 text-[--accent-9] hover:text-[--accent-11] data-[active=true]:bg-[--accent-indicator] data-[active=true]:text-[--accent-contrast]"
          >
            <Icon className="size-5" />
            <Text size="3" weight="medium">{teamNavItems[id].title}</Text>
          </Link>
        )
      })}
    </Flex>
  )
}

function ProjectSelect({ teamId }: { teamId: string }) {
  const { projects, error, isLoading } = useProjects(teamId)
  const teamStore = useTeamStore()
  const projectStore = useProjectStore()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  function handleProjectChange(projectName: string) {
    navigate(`/${teamStore.currentTeam.slug}/${projectName}`)
  }

  if (isLoading)
    return <div></div>

  if (error)
    return <div>Fail to get projects</div>

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger>
        <Button
          variant="soft"
          highContrast
          className="relative w-full items-center justify-between [--base-button-height:2.5rem]"
        >
          <Box className="GridLine GridLine--Y absolute left-8 top-0 w-2/3" />
          <Box className="GridLine GridLine--Y absolute bottom-0 left-4 w-4/5" />
          <Box className="GridLine GridLine--X absolute left-0 top-2 h-4" />
          <Box className="GridLine GridLine--X absolute right-0 top-0 h-2" />
          <Flex gap="2" ml="-1" maxWidth="90%">
            <Avatar
              fallback={projectStore.currentProject.name?.[0]}
              size="1"
              radius="medium"
              variant="solid"
            />
            <Text truncate className="flex-1">{projectStore.currentProject.name}</Text>
          </Flex>
          <CaretSortIcon />
        </Button>
      </Popover.Trigger>
      <Popover.Content className="p-0" side="bottom">
        <Command>
          <CommandInput placeholder="Search project..." className="h-9" />
          <CommandEmpty>No projects found.</CommandEmpty>
          <CommandList>
            <CommandGroup>
              {projects.map(project => (
                <CommandItem
                  key={project.id}
                  value={project.name}
                  onSelect={(projectName) => {
                    setOpen(false)
                    handleProjectChange(projectName)
                  }}
                >
                  <CheckIcon
                    className={cn(
                      'mr-2 h-4 w-4',
                      projectStore.currentProject.name === project.name ? 'opacity-100' : 'opacity-0',
                    )}
                  />
                  <Text size="2">
                    {project.name}
                  </Text>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </Popover.Content>
    </Popover.Root>
  )
}

const projectNavIds = ['projectAIBridge', 'projectServerless', 'projectUsage', 'projectLog', 'projectConfig', 'projectSetting'] as const
type ProjectNavId = typeof projectNavIds[number]
const projectNavItems: Record<ProjectNavId, {
  title: string
  href: (teamSlug: string, projectName: string) => string
  icon: React.FC<{ className?: string }>
}> = {
  projectAIBridge: {
    title: 'AI Bridge',
    href(teamSlug: string, projectName: string) {
      return `/${teamSlug}/${projectName}`
    },
    icon: HexagonsIcon,
  },
  projectServerless: {
    title: 'Serverless',
    href(teamSlug: string, projectName: string) {
      return `/${teamSlug}/${projectName}/serverless`
    },
    icon: ServerlessIcon,
  },
  projectUsage: {
    title: 'Usage',
    href(teamSlug: string, projectName: string) {
      return `/${teamSlug}/${projectName}/usage`
    },
    icon: UsageIcon,
  },
  projectConfig: {
    title: 'Configuration',
    href(teamSlug: string, projectName: string) {
      return `/${teamSlug}/${projectName}/config`
    },
    icon: ConfigurationIcon,
  },
  projectSetting: {
    title: 'Settings',
    href(teamSlug: string, projectName: string) {
      return `/${teamSlug}/${projectName}/settings`
    },
    icon: CogIcon,
  },
  projectLog: {
    title: 'Log',
    href(teamSlug: string, projectName: string) {
      return `/${teamSlug}/${projectName}/log`
    },
    icon: FileTextIcon,
  },
}

function ProjectNavbar() {
  const params = useParams()
  let activeProjectNav: ProjectNavId = 'projectAIBridge'

  const pathname = params['*'] || ''
  if (pathname.endsWith('settings'))
    activeProjectNav = 'projectSetting'
  else if (pathname.endsWith('usage'))
    activeProjectNav = 'projectUsage'
  else if (pathname.endsWith('config'))
    activeProjectNav = 'projectConfig'
  else if (pathname.endsWith('serverless'))
    activeProjectNav = 'projectServerless'
  else if (pathname.endsWith('log'))
    activeProjectNav = 'projectLog'
  else
    activeProjectNav = 'projectAIBridge'

  return (
    <Flex gap="3" direction="column" my="4">
      <Link
        to={`/${params.teamSlug}`}
        className="flex items-center gap-2 bg-transparent px-3 text-[--accent-9] hover:text-[--accent-11]"
      >
        <BackIcon className="size-3" />
        <Text size="1" weight="medium">Back to Team</Text>
      </Link>
      <Flex justify="center">
        <Box className="h-1 w-full border-b border-dashed border-[--accent-a7]" />
      </Flex>

      <Flex gap="2" direction="column">
        {projectNavIds.map((id) => {
          const Icon = projectNavItems[id].icon
          return (
            <Link
              key={id}
              to={projectNavItems[id].href(params.teamSlug!, params.projectName!)}
              data-active={activeProjectNav === id}
              className="flex h-10 items-center gap-2 rounded-[--radius-4] bg-transparent px-3 text-[--accent-9] hover:text-[--accent-11] data-[active=true]:bg-[--accent-indicator] data-[active=true]:text-[--accent-contrast]"
            >
              <Icon className="size-5" />
              <Text size="3" weight="medium">{projectNavItems[id].title}</Text>
            </Link>
          )
        })}
      </Flex>
    </Flex>
  )
}
