import { ofetch } from 'ofetch'
import { config } from '../config'
import { logto, signIn } from './logto'

export type ApiResult<T> = { ok: true, data: T } | { ok: false, error: string }

async function setRequestOptions({ options }: { options: any }) {
  options.headers = options.headers || {}
  if (!config.billingDebugMode)
    options.headers['X-Allegro-Live'] = true

  try {
    const token = await logto.getAccessToken('https://dashboardapi.vivgrid.com');
    (options.headers as any).Authorization = `Bearer ${token}`
  }
  catch (error) {
    signIn()
  }

  options.params = {
    ...options.params,
    _t: Date.now(),
  }
}

function createApiFetch(baseURL: string) {
  return ofetch.create({
    baseURL,
    async onRequest({ options }) {
      await setRequestOptions({ options })
    },
  })
}

export const apiFetch = createApiFetch(config.apiBaseURL)
export const apiBillingFetch = createApiFetch(`${config.apiBaseURL}/billing`)
export const apiStorageFetch = createApiFetch(`${config.apiBaseURL}/storage`)
