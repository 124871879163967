import React from 'react'

export function CreateTeamIcon(props: React.SVGProps<SVGSVGElement>) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = fill

  return (
    <svg height="1em" width="1em" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="m3,7v-1c0-1.657,1.343-3,3-3h1" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path d="m7,17h-1c-1.657,0-3-1.343-3-3v-1" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path d="m17,13v1c0,1.657-1.343,3-3,3h-1" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path d="m13,3h1c1.657,0,3,1.343,3,3v1" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <line fill="none" stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="10" x2="10" y1="14" y2="6" />
        <line fill="none" stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="6" x2="14" y1="10" y2="10" />
      </g>
    </svg>
  )
}
