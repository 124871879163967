import React from 'react'

export function UserSettingsIcon(props: React.SVGProps<SVGSVGElement>) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = fill

  return (
    <svg height="1em" width="1em" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <circle cx="10" cy="6" fill="none" r="3" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path d="m7.926,12.324c-1.654.514-3.05,1.613-3.927,3.068" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path d="m9,17.992l.5-3,4.707-4.699c.391-.391,1.024-.391,1.414,0l1.086,1.086c.391.391.391,1.024,0,1.414l-4.707,4.699-3,.5Z" fill="none" stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </g>
    </svg>
  )
}
