import { Text } from '@radix-ui/themes'
import useSWR from 'swr'

export function UserName({ userId, size }: { userId: string, size?: any }) {
  const { data, error, isLoading } = useSWR(`/users/${userId}`)

  if (isLoading)
    return null

  if (error)
    return null

  return <Text size={size}>{data.data.name || data.data.email}</Text>
}
