import { InfoCircledIcon } from '@radix-ui/react-icons'
import { Callout, Card, Code, Flex, Heading, Inset, Section, Text } from '@radix-ui/themes'
import ReactPrismjs from '@uiw/react-prismjs'
import 'prismjs/components/prism-yaml'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { useProjectStore } from '~/stores/project.ts'
import { Content } from '~/views/layout/Content.tsx'
import { ServerlessFunctions } from '~/views/project/ServerlessFunctions.tsx'

export function ProjectServerless() {
  const projectStore = useProjectStore()
  const currentProject = projectStore.currentProject

  const ycYmlStr = `app-key: ${currentProject.appKey}
app-secret: ${currentProject.appSecret}
sfn-name: my_first_llm_function_tool`

  const envStr = `YOMO_SFN_NAME=my_first_llm_function_tool
YOMO_SFN_ZIPPER="zipper.vivgrid.com:9000"
YOMO_SFN_CREDENTIAL="app-key-secret:${currentProject.appKey}.${currentProject.appSecret}"`

  return (
    <Content title="Serverless">
      <Helmet>
        <title>
          Serverless /
          {' '}
          {projectStore.currentProject.name}
        </title>
      </Helmet>

      <Section size="2" pt="0">
        <Flex direction="column" gap="4">
          <Flex direction="column" gap="1">
            <Heading size="5">Local Development</Heading>
            <Text size="2" color="gray">
              Your
              {' '}
              <Code>.env</Code>
              {' '}
              for running Stateful Serverless Function Calling on your local dev machine by
              {' '}
              <Code>yomo run app.go</Code>
            </Text>
          </Flex>

          <Card size="3">
            <Inset>
              <ReactPrismjs className="!text-lg" source={envStr} language="shell" />
            </Inset>
          </Card>
        </Flex>
      </Section>

      <Section size="2" pt="0">
        <Flex direction="column" gap="4">
          <Flex direction="column" gap="1">
            <Heading size="5">Global Deployment</Heading>
            <Text size="2" color="gray">
              Your
              {' '}
              <Code>yc.yml</Code>
              {' '}
              for deploying Stateful Serverless Function Calling geo-distributed
            </Text>
          </Flex>

          <Card size="3">
            <Inset>
              <ReactPrismjs className="!text-lg" source={ycYmlStr} language="yaml" />
            </Inset>

          </Card>

          <Callout.Root>
            <Callout.Icon>
              <InfoCircledIcon />
            </Callout.Icon>
            <Callout.Text>
              Follow
              {' '}
              <Link to="https://docs.vivgrid.com/function-calling" target="_blank" className="underline">this tutorial</Link>
              {' '}
              to create your OpenAI Function Calling Serverless in a strong-typed language.
            </Callout.Text>
          </Callout.Root>
        </Flex>
      </Section>

      <Section size="2" pt="0">
        <Flex direction="column" gap="4">
          <Flex direction="column" gap="1">
            <Heading size="5">Serverless Functions</Heading>
            <Text size="2" color="gray">
              List of serverless functions in this Project
            </Text>
          </Flex>

          <Card size="3">
            <ServerlessFunctions />
          </Card>
        </Flex>
      </Section>
    </Content>
  )
}
