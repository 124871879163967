import { Spinner } from '@radix-ui/themes'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMount } from 'react-use'
import { logto } from '~/libs/logto'

export function SignInCallback() {
  const [authed, setAuthed] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()

  useMount(async () => {
    try {
      await logto.handleSignInCallback(window.location.href)
    }
    catch (error) {
      return { isAuthenticated: false }
    }

    const isAuthenticated = await logto.isAuthenticated()
    if (isAuthenticated)
      setAuthed(true)
    else
      setError('Failed to sign in')
  })

  if (authed) {
    navigate('/')
    return null
  }

  if (error)
    return <div>{error}</div>

  return <div className="mt-6 flex justify-center"><Spinner size="3" /></div>
}
