import React from 'react'

export function HexagonsIcon(props: React.SVGProps<SVGSVGElement>) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = fill

  return (
    <svg height="1em" width="1em" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M11.75,15.291c-.389,0-.778-.1-1.125-.301l-3.5-2.021c-.694-.401-1.125-1.147-1.125-1.949V6.75c0-.414,.336-.75,.75-.75s.75,.336,.75,.75v4.271c0,.267,.144,.516,.375,.65l3.5,2.021c.232,.134,.52,.133,.75,0l3.5-2.021c.231-.134,.375-.383,.375-.65V6.979c0-.267-.144-.516-.375-.65l-3.5-2.021c-.128-.074-.269-.108-.424-.099-.413,.022-.771-.286-.797-.7-.027-.413,.286-.77,.7-.797,.444-.026,.885,.073,1.271,.297l3.5,2.021c.694,.401,1.125,1.147,1.125,1.949v4.041c0,.801-.431,1.548-1.125,1.949l-3.5,2.021c-.347,.201-.736,.301-1.125,.301Z" fill={secondaryfill} />
        <path d="M6.249,15.292c-.395,0-.78-.103-1.124-.302l-3.5-2.021c-.694-.401-1.125-1.147-1.125-1.949V6.979c0-.801,.431-1.548,1.125-1.949l3.5-2.021c.693-.401,1.556-.401,2.25,0l3.5,2.021c.694,.401,1.125,1.147,1.125,1.949v4.271c0,.414-.336,.75-.75,.75s-.75-.336-.75-.75V6.979c0-.267-.144-.516-.375-.65l-3.5-2.021c-.232-.134-.52-.133-.75,0l-3.5,2.021c-.231,.134-.375,.383-.375,.65v4.041c0,.267,.144,.516,.375,.65l3.5,2.021c.129,.074,.274,.108,.424,.099,.403-.024,.771,.286,.797,.7,.027,.413-.286,.77-.7,.797-.049,.003-.099,.005-.148,.005Z" fill={fill} />
      </g>
    </svg>
  )
}
