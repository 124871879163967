import { Flex, Grid, Heading, Section, Text } from '@radix-ui/themes'
import { Helmet } from 'react-helmet-async'
import { useProjectStore } from '~/stores/project.ts'
import { Content } from '~/views/layout/Content.tsx'
import { TokenUsage } from '~/views/project/TokenUsage.tsx'

export function ProjectUsage() {
  const projectStore = useProjectStore()
  return (
    <Content title="Usage">
      <Helmet>
        <title>
          Usage /
          {' '}
          {projectStore.currentProject.name}
        </title>
      </Helmet>

      <Section size="2" pt="0">
        <Flex direction="column" gap="4">
          <Flex direction="column" gap="1">
            <Heading size="5">Current Billing Cycle</Heading>
            <Text size="2" color="gray">
              Usage of the LLM token
            </Text>
          </Flex>
          <Grid columns={{ initial: '1', md: '2' }} gap="4" width="auto">
            <TokenUsage />
          </Grid>
        </Flex>
      </Section>
    </Content>
  )
}
