import { Link } from 'react-router-dom'
import { Box, Flex, Grid, Text } from '@radix-ui/themes'
import { Helmet } from 'react-helmet-async'
import { UpdateAvatar } from '~/views/account/UpdateAvatar'
import { UpdateName } from '~/views/account/UpdateName'
import { Content } from '~/views/layout/Content.tsx'

export function AccountSetting() {
  return (
    <Content title="Account Settings" row>
      <Helmet>
        <title>
          Account Settings
        </title>
      </Helmet>
      <Box width="15rem">
        <Grid gap="4">
          <Link to="#">
            <Text size="3" weight="medium">General</Text>
          </Link>
        </Grid>
      </Box>
      <Flex direction="column" gap="6" className="flex-1">
        <UpdateName />
        <UpdateAvatar />
      </Flex>
    </Content>
  )
}
