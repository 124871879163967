import { createBrowserRouter } from 'react-router-dom'

import { SignInCallback } from './SignInCallback'
import { RedirectToDefaultTeam, Team } from './Team'
import { App } from './App'
import { TeamOverview } from './TeamOverview'
import TeamSetting from './TeamSetting.tsx'
import { NotFound } from './NotFound'
import { Project } from './Project'
import ProjectSetting from './projects/ProjectSetting.tsx'
import { NewProject } from './NewProject'
import { AccountSetting } from './AccountSetting'
import { TeamLLM } from './TeamLLM'
import { Billing } from './Billing.tsx'
import { ProjectConfig } from './projects/ProjectConfig.tsx'
import { PaymentResult } from './PaymentResult.tsx'
import { CreateTeam } from './CreateTeam.tsx'
import { TeamUsage } from './TeamUsage.tsx'
import { ProjectUsage } from '~/routes/projects/ProjectUsage.tsx'
import { ProjectBridge } from '~/routes/projects/ProjectBridge.tsx'
import { ProjectServerless } from '~/routes/projects/ProjectServerless.tsx'
import { ProjectLog } from '~/routes/projects/ProjectLog.tsx'

export const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
    children: [
      {
        index: true,
        element: <RedirectToDefaultTeam />,
      },
      {
        path: 'account',
        element: <AccountSetting />,
      },
      {
        path: 'create-team',
        element: <CreateTeam />,
      },
      {
        path: ':teamSlug',
        element: <Team />,
        children: [
          {
            id: 'teamOverview',
            index: true,
            element: <TeamOverview />,
          },
          {
            id: 'teamLLM',
            path: '~/llm',
            element: <TeamLLM />,
          },
          {
            id: 'teamSetting',
            path: '~/settings',
            element: <TeamSetting />,
          },
          {
            id: 'teamNewProject',
            path: '~/new',
            element: <NewProject />,
          },
          {
            id: 'teamBilling',
            path: '~/billing',
            element: <Billing />,
          },
          {
            id: 'teamUsage',
            path: '~/usage',
            element: <TeamUsage />,
          },
          {
            path: ':projectName',
            element: <Project />,
            children: [{
              id: 'projectConfig',
              path: 'config',
              element: <ProjectConfig />,
            }, {
              id: 'projectUsage',
              path: 'usage',
              element: <ProjectUsage />,
            }, {
              id: 'projectBridge',
              index: true,
              element: <ProjectBridge />,
            }, {
              id: 'projectServerless',
              path: 'serverless',
              element: <ProjectServerless />,
            }, {
              id: 'projectSetting',
              path: 'settings',
              element: <ProjectSetting />,
            }, {
              id: 'projectLog',
              path: 'log',
              element: <ProjectLog />,
            }],
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: '/payment/result',
    element: <PaymentResult />,
  },
  {
    path: '/auth/callback',
    element: <SignInCallback />,
  },
])
