import { Button, Card, Flex, Inset, Separator, Text, TextField } from '@radix-ui/themes'
import { useState } from 'react'
import { toast } from 'sonner'
import { apiFetch } from '~/libs/fetcher'
import { fetchCurrentTeam, useTeamStore } from '~/stores/team'

export function UpdateTeamName() {
  const teamStore = useTeamStore()
  const [name, setName] = useState(teamStore.currentTeam.name)

  async function handleSave() {
    if (name.length < 1 || name.length > 32) {
      toast.error('Name must be between 1 and 32 characters')
      return
    }

    try {
      const res = await apiFetch(`/teams/${teamStore.currentTeam.id}/name`, {
        method: 'PATCH',
        query: { name },
      })
      if (res.ok) {
        toast.success('Name updated')
        fetchCurrentTeam()
        return
      }

      toast.error('Failed to update name')
    }
    catch (error) {
      toast.error('Failed to update name')
    }
  }

  return (
    <Card size="3">
      <Flex direction="column" gap="4">
        <Flex direction="column" gap="1">
          <Text size="5" weight="bold">Team Name</Text>
          <Text size="2" className="text-[--accent-9]">This is your team's visible name within vivgrid.</Text>
        </Flex>
        <TextField.Root size="3" placeholder="" value={name} onChange={e => setName(e.target.value)} />
        <Inset my="6">
          <Separator size="4" />
        </Inset>
        <Flex justify="between" align="center">
          <Text size="2" className="text-[--gray-a9]">Please use 32 characters at maximum.</Text>
          <Button onClick={handleSave}>Save</Button>
        </Flex>
      </Flex>
    </Card>
  )
}
