import React from 'react'

export function BillingIcon(props: React.SVGProps<SVGSVGElement>) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = fill

  return (
    <svg height="1em" width="1em" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path fill={secondaryfill} d="M27.05 7H5a3 3 0 0 0-3 3v3a1 1 0 0 0 1 1h22a1 1 0 0 0 0-2H4v-2a1 1 0 0 1 1-1h22.1a1 1 0 0 1 .9 1v12.1a1 1 0 0 1-.95.95H5a1 1 0 0 1-1-.95V17a1 1 0 0 0-2 0v5.05A3 3 0 0 0 5 25h22.1a3 3 0 0 0 2.9-2.95V10a3 3 0 0 0-2.95-3z" />
        <path d="M10 21a1 1 0 0 0 0-2H7a1 1 0 0 0 0 2z" />
      </g>
    </svg>
  )
}
