import React from 'react'

export function LogOutIcon(props: React.SVGProps<SVGSVGElement>) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = fill

  return (
    <svg height="1em" width="1em" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <polyline fill="none" points="7 14 3 10 7 6" stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <line fill="none" stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="12" x2="3" y1="10" y2="10" />
        <path d="m11,3h3c1.657,0,3,1.343,3,3v8c0,1.657-1.343,3-3,3h-3" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </g>
    </svg>
  )
}
