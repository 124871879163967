import { Button, TextField } from '@radix-ui/themes'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { Helmet } from 'react-helmet-async'
import { apiFetch } from '~/libs/fetcher'
import { useTeamStore } from '~/stores/team'
import { Content } from '~/views/layout/Content.tsx'

interface IFormInput {
  name: string
  description: string
}

export function NewProject() {
  const teamStore = useTeamStore()
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { isSubmitting, errors } } = useForm({
    defaultValues: {
      name: '',
      description: '',
    },
  })

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      const res = await apiFetch(`/teams/${teamStore.currentTeam.id}/projects`, {
        method: 'POST',
        body: {
          name: data.name,
          description: data.description,
        },
      })

      if (res.ok) {
        toast.success('Project created successfully')
        navigate(`/${teamStore.currentTeam.slug}/${data.name}`)
        return
      }
    }
    catch (error) {}

    toast.error('Failed to create project')
  }

  return (
    <Content title="New Project">
      <Helmet>
        <title>
          New Project
        </title>
      </Helmet>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-lg font-semibold">Name</div>
        <TextField.Root size="3" className="max-w-64" {...register('name', { required: true, pattern: /^[a-z0-9._-]{1,100}$/ })} placeholder="" />
        {errors.name && (
          <div className="text-red-500">
            <div>Invalid name.</div>
            <div>lowercase letters, numbers, and ._- allowed</div>
            <div>Max 100 characters.</div>
          </div>
        )}

        <div className="mt-6 text-lg font-semibold">Description</div>
        <TextField.Root
          size="3"
          className="max-w-64"
          {...register('description', {
            maxLength: { value: 100, message: 'Invalid description.\nMax 100 characters' },
          })}
          placeholder=""
        />
        {errors.description && <div className="whitespace-pre-wrap text-red-500">{errors.description.message}</div>}
        <Button className="mt-6" size="3" type="submit" loading={isSubmitting}>Create Project</Button>
      </form>
    </Content>
  )
}
