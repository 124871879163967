import React from 'react'

export function MicrochipIcon(props: React.SVGProps<SVGSVGElement>) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = fill

  return (
    <svg height="1em" width="1em" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <rect height="10.5" width="10.5" fill="none" rx="2" ry="2" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x="3.75" y="3.75" />
        <rect height="4.5" width="4.5" fill="none" stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" transform="translate(0 18) rotate(-90)" x="6.75" y="6.75" />
        <line fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="9" x2="9" y1="1.75" y2="3.75" />
        <line fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="5.75" x2="5.75" y1="1.75" y2="3.75" />
        <line fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="12.25" x2="12.25" y1="1.75" y2="3.75" />
        <line fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="16.25" x2="14.25" y1="9" y2="9" />
        <line fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="16.25" x2="14.25" y1="5.75" y2="5.75" />
        <line fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="16.25" x2="14.25" y1="12.25" y2="12.25" />
        <line fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="9" x2="9" y1="16.25" y2="14.25" />
        <line fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="12.25" x2="12.25" y1="16.25" y2="14.25" />
        <line fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="5.75" x2="5.75" y1="16.25" y2="14.25" />
        <line fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="1.75" x2="3.75" y1="9" y2="9" />
        <line fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="1.75" x2="3.75" y1="12.25" y2="12.25" />
        <line fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="1.75" x2="3.75" y1="5.75" y2="5.75" />
      </g>
    </svg>
  )
}
