import LogtoClient from '@logto/browser'
import { config } from '~/config'

export const logto = new LogtoClient(config.logto)

export function signIn() {
  logto.signIn(`${location.origin}/auth/callback`)
}

export function signOut() {
  logto.signOut(location.origin)
}
