import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import './index.css'
import { Toaster } from 'sonner'
import { HelmetProvider } from 'react-helmet-async'
import { SWRConfig } from 'swr'
import * as Sentry from '@sentry/react'
import { router } from './routes'
import { apiFetch } from './libs/fetcher'
import { ThemeProvider } from '~/components/ThemeProvider.tsx'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Toaster richColors position="top-center" />
    <ThemeProvider>
      <HelmetProvider>
        <SWRConfig value={{ fetcher: apiFetch }}>
          <div id="app">
            <RouterProvider router={router} />
          </div>
        </SWRConfig>
      </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>,
)

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://3855bfefbb025efa8388d3b8b9f6f5a3@o4507509664579584.ingest.us.sentry.io/4507509669888000',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.vivgrid\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
