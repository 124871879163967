import { Button, Flex, Text } from '@radix-ui/themes'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Content } from '~/views/layout/Content.tsx'

export function NotFound() {
  return (
    <Content title="Uh oh...">
      <Helmet>
        <title>
          Not Found
        </title>
      </Helmet>

      <Flex direction="column" gap="4" maxWidth="25rem">
        <Text>
          The page you're looking for doesn't exist.
          {' '}
          <span role="img" aria-label="Sad face">
            😢
          </span>
        </Text>

        <Button size="3" asChild>
          <Link to="/">
            Back to Dashboard
          </Link>
        </Button>
      </Flex>
    </Content>
  )
}
