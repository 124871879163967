import type { PropsWithChildren } from 'react'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { Theme } from '@radix-ui/themes'

interface ThemeContextProps {
  theme: 'system' | 'dark' | 'light'
  setTheme: (theme: 'system' | 'dark' | 'light') => void
  roundness: 'none' | 'small' | 'large'
  setRoundness: (roundness: 'none' | 'small' | 'large') => void
}

export const ThemeContext = createContext<ThemeContextProps>({ theme: 'system', setTheme: () => {}, roundness: 'none', setRoundness: () => {} })

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState<'system' | 'dark' | 'light'>('system')
  const [roundness, setRoundness] = useState<'none' | 'small' | 'large'>('none')

  const applyTheme = (theme: 'system' | 'dark' | 'light') => {
    let appliedTheme: 'dark' | 'light'
    if (theme === 'system') {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
      appliedTheme = prefersDark ? 'dark' : 'light'
    }
    else {
      appliedTheme = theme
    }

    document.documentElement.classList.remove('dark', 'light')
    document.documentElement.classList.add(appliedTheme)
  }

  useEffect(() => {
    const savedTheme = (localStorage.getItem('theme') as 'system' | 'dark' | 'light') || 'system'
    const savedRoundness = (localStorage.getItem('roundness') as 'none' | 'small' | 'large') || 'none'
    setTheme(savedTheme)
    setRoundness(savedRoundness)
    applyTheme(savedTheme)

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    const handleChange = () => {
      if (theme === 'system')
        applyTheme('system')
    }

    mediaQuery.addEventListener('change', handleChange)

    return () => {
      mediaQuery.removeEventListener('change', handleChange)
    }
  }, [theme])

  const handleSetTheme = (newTheme: 'system' | 'dark' | 'light') => {
    localStorage.setItem('theme', newTheme)
    setTheme(newTheme)
    applyTheme(newTheme)
  }
  const handleSetRoundness = (newRoundness: 'none' | 'small' | 'large') => {
    localStorage.setItem('roundness', newRoundness)
    setRoundness(newRoundness)
  }

  const value = useMemo(() => ({ theme, setTheme: handleSetTheme, roundness, setRoundness: handleSetRoundness }), [theme, roundness])

  return (
    <ThemeContext.Provider value={value}>
      <Theme
        accentColor="jade"
        grayColor="olive"
        radius={roundness}
        panelBackground="translucent"
      >
        {children}
      </Theme>
    </ThemeContext.Provider>
  )
}
