import React from 'react'

export function GridListIcon(props: React.SVGProps<SVGSVGElement>) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = fill

  return (
    <svg height="1em" width="1em" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M6.25 2.75H3.75C3.19772 2.75 2.75 3.19772 2.75 3.75V6.25C2.75 6.80228 3.19772 7.25 3.75 7.25H6.25C6.80228 7.25 7.25 6.80228 7.25 6.25V3.75C7.25 3.19772 6.80228 2.75 6.25 2.75Z" fill={secondaryfill} fillOpacity="0.3" stroke="none" />
        <path d="M6.25 10.75H3.75C3.19772 10.75 2.75 11.1977 2.75 11.75V14.25C2.75 14.8023 3.19772 15.25 3.75 15.25H6.25C6.80228 15.25 7.25 14.8023 7.25 14.25V11.75C7.25 11.1977 6.80228 10.75 6.25 10.75Z" fill={secondaryfill} fillOpacity="0.3" stroke="none" />
        <path d="M10.25 3.75H15.25" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path d="M10.25 6.75H15.25" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path d="M10.25 11.25H15.25" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path d="M10.25 14.25H15.25" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path d="M6.25 2.75H3.75C3.19772 2.75 2.75 3.19772 2.75 3.75V6.25C2.75 6.80228 3.19772 7.25 3.75 7.25H6.25C6.80228 7.25 7.25 6.80228 7.25 6.25V3.75C7.25 3.19772 6.80228 2.75 6.25 2.75Z" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path d="M6.25 10.75H3.75C3.19772 10.75 2.75 11.1977 2.75 11.75V14.25C2.75 14.8023 3.19772 15.25 3.75 15.25H6.25C6.80228 15.25 7.25 14.8023 7.25 14.25V11.75C7.25 11.1977 6.80228 10.75 6.25 10.75Z" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </svg>
  )
}
