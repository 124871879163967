interface ProgressRingProps {
  current: number
  max: number
  size?: number
  strokeWidth?: number
  progressColor?: string
}

export default function ProgressRing({
  current,
  max,
  size = 100,
  strokeWidth = 10,
  progressColor = 'var(--accent-9)',
}: ProgressRingProps) {
  const progress = max > 0 ? Math.min(100, Math.max(0, (current / max) * 100)) : 0

  const center = size / 2
  const radius = center - strokeWidth / 2
  const circumference = 2 * Math.PI * radius

  const strokeDashoffset = Number.isNaN(circumference) || Number.isNaN(progress)
    ? 0
    : circumference - (progress / 100) * circumference

  const strokeDashoffsetStr = strokeDashoffset.toString()

  return (
    <div className="relative" style={{ width: size, height: size }}>
      <svg className="-rotate-90" width={size} height={size}>
        <circle
          className="text-muted"
          strokeWidth={strokeWidth}
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={center}
          cy={center}
        />
        <circle
          style={{
            color: progressColor,
          }}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffsetStr}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={center}
          cy={center}
        />
      </svg>
    </div>
  )
}
