import { Helmet } from 'react-helmet-async'
import { Button, Card, Flex, Heading, Popover, Text } from '@radix-ui/themes'
import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons'
import { useState } from 'react'
import { Content } from '~/views/layout/Content.tsx'
import { useProjectStore } from '~/stores/project.ts'
import { Command, CommandGroup, CommandItem, CommandList } from '~/components/command'
import { cn } from '~/libs/utils.ts'
import { ProjectLogTable } from '~/views/project/ProjectLogTable.tsx'

export function ProjectLog() {
  const projectStore = useProjectStore()
  const [amount, setAmount] = useState(50)

  // eslint-disable-next-line react/no-nested-components
  function LogAmountSelect() {
    const [open, setOpen] = useState(false)

    function handleAmountChange(value: string) {
      const newAmount = Number.parseInt(value)
      if (!Number.isNaN(newAmount) && [50, 100, 200].includes(newAmount))
        setAmount(newAmount)
      else
        console.error(`Invalid amount value: ${value}`)

      setOpen(false)
    }

    const options = [50, 100, 200]

    return (
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger className="max-w-96">
          <Button
            variant="soft"
            highContrast
            className="relative w-full items-center justify-between [--base-button-height:2.5rem]"
          >
            <Flex gap="2" ml="-1" maxWidth="90%">
              <Text truncate className="flex-1">
                Amount of logs:
                {' '}
                {amount}
              </Text>
            </Flex>
            <CaretSortIcon />
          </Button>
        </Popover.Trigger>
        <Popover.Content className="p-0" side="bottom">
          <Command>
            <div className="flex h-10 w-full items-center border-b bg-transparent p-3 text-xs outline-none">Select amount of logs to show</div>
            <CommandList>
              <CommandGroup>
                {options.map(value => (
                  <CommandItem key={value} value={value.toString()} onSelect={handleAmountChange}>
                    <CheckIcon className={cn('mr-2 h-4 w-4', amount === value ? 'opacity-100' : 'opacity-0')} />
                    <Text size="2">{value}</Text>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </Popover.Content>
      </Popover.Root>
    )
  }

  return (
    <Content title="Project Log">
      <Helmet>
        <title>
          Log /
          {' '}
          {projectStore.currentProject.name}
        </title>
      </Helmet>

      <Flex direction="column" gap="6" className="h-0 flex-1">
        <Heading size="5" className="shrink-0 grow-0">Project Log</Heading>
        <Text size="2" className="shrink-0 grow-0">This is where you can see the log of your project.</Text>
        <LogAmountSelect />
        <Card className="relative flex-1">
          <div className="absolute inset-0">
            <ProjectLogTable pageSize={amount} />
          </div>
        </Card>
      </Flex>
    </Content>
  )
}
