import { Skeleton } from '@radix-ui/themes'
import { Outlet, useNavigate } from 'react-router-dom'
import { useMount } from 'react-use'
import { useTeamStore } from '~/stores/team'
import { useUserStore } from '~/stores/user'

export function Team() {
  return (
    <>
      <Outlet />
    </>
  )
}

export function RedirectToDefaultTeam() {
  const me = useUserStore().me
  const allTeams = useTeamStore().allTeams
  const navigate = useNavigate()

  useMount(() => {
    let defaultTeam = allTeams.find(team => team.id === me.defaultTeamId)
    if (!defaultTeam)
      defaultTeam = allTeams[0]
    navigate(`/${defaultTeam.slug}`)
  })

  return (
    <Skeleton>Redirecting to default team...</Skeleton>
  )
}
