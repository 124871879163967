import { Skeleton } from '@radix-ui/themes'
import { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { Helmet } from 'react-helmet-async'
import { fetchCurrentProject, useProjectStore } from '~/stores/project'

export function Project() {
  const params = useParams()
  const projectStore = useProjectStore()
  const currentProjectName = projectStore.currentProject.name

  useEffect(() => {
    if (!currentProjectName) {
      fetchCurrentProject(params.projectName!).catch((err) => {
        console.error(err)
        toast.error('Failed to get project')
      })

      return
    }

    if (currentProjectName !== params.projectName) {
      fetchCurrentProject(params.projectName!).catch((err) => {
        console.error(err)
        toast.error('Failed to get project')
      })
    }
  }, [params.projectName, currentProjectName])

  return (
    <>
      {projectStore.currentProject.name
        ? (
          <>
            <Helmet>
              <title>
                {projectStore.currentProject.name}
                {' '}
                - Project
              </title>
            </Helmet>
            <Outlet />
          </>
          )
        : <Skeleton />}
    </>
  )
}
