import { Box, Button, Card, Flex, Inset, Separator, Text, TextField } from '@radix-ui/themes'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import { useRef, useState } from 'react'
import { useMount } from 'react-use'
import { toast } from 'sonner'
import { apiFetch } from '~/libs/fetcher'
import { useProjectStore } from '~/stores/project'

export function UpdateBaselime() {
  const projectStore = useProjectStore()
  const [inputApiKey, setInputApiKey] = useState('')
  const [showApiKey, setShowApiKey] = useState(false)
  const [saving, setSaving] = useState(false)
  const settingsLoad = useRef(false)

  useMount(() => {
    if (settingsLoad.current)
      return
    if (!projectStore.currentProject.id || !projectStore.currentProject.teamId)
      return
    settingsLoad.current = true
    const getProjectSettings = async () => {
      try {
        const res = await apiFetch(`/teams/${projectStore.currentProject.teamId}/projects/${projectStore.currentProject.id}/settings`)
        if (!res.ok)
          return toast.error('Failed to fetch project settings')
        setInputApiKey(res.data.baselime_api_key)
      }
      catch (error) {
        toast.error('Failed to fetch project settings')
      }
    }
    getProjectSettings()
  })

  async function handleSave() {
    const value = inputApiKey.trim()
    if (!value)
      return toast.error('Invalid API key')
    setSaving(true)
    try {
      const res = await apiFetch(`/teams/${projectStore.currentProject.teamId}/projects/${projectStore.currentProject.id}/settings`, {
        method: 'POST',
        body: {
          baselime_api_key: value,
        },
      })
      if (!res.ok)
        toast.error('Failed to update API key')
      toast.success('API key updated')
    }
    catch (error) {
      toast.error('Failed to update API key')
    }
    finally {
      setSaving(false)
    }
  }

  return (
    <Card size="3">
      <Flex direction="column" gap="4">
        <Flex direction="column" gap="1">
          <Text size="5" weight="bold">Baselime API Key</Text>
          <Text size="2" className="text-[--accent-9]">
            Set the Baselime API key for your project.
          </Text>
        </Flex>

        <TextField.Root size="3" placeholder="" value={inputApiKey} onChange={e => setInputApiKey(e.target.value.trim())} type={showApiKey ? 'text' : 'password'}>
          <TextField.Slot side="right">
            <Box onClick={() => setShowApiKey(!showApiKey)} className="cursor-pointer">{showApiKey ? <EyeIcon /> : <EyeOffIcon />}</Box>
          </TextField.Slot>
        </TextField.Root>

        <Inset my="6">
          <Separator size="4" />
        </Inset>
        <Flex justify="end" align="center">
          <Button onClick={handleSave} loading={saving}>Save</Button>
        </Flex>
      </Flex>
    </Card>
  )
}
