import { Badge, Button, Card, Flex, Inset, Separator, Text, TextField } from '@radix-ui/themes'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { apiFetch } from '~/libs/fetcher'
import { useProjectStore } from '~/stores/project'
import { teamStore } from '~/stores/team.ts'

export function UpdateProjectName() {
  const projectStore = useProjectStore()
  const navigate = useNavigate()
  const [name, setName] = useState(projectStore.currentProject.name)

  async function handleSave() {
    if (/^[a-z0-9._-]{1,100}$/.test(name) === false) {
      toast.error('Invalid name')
      return
    }

    try {
      const res = await apiFetch(`/teams/${projectStore.currentProject.teamId}/projects/${projectStore.currentProject.id}/name`, {
        method: 'PATCH',
        query: { name },
      })
      if (res.ok) {
        toast.success('Name updated')
        navigate(`/${projectStore.currentProject.teamId}/${name}/settings`)
        return
      }

      toast.error('Failed to update name')
    }
    catch (error) {
      toast.error('Failed to update name')
    }
  }

  return (
    <Card size="3">
      <Flex direction="column" gap="4">
        <Flex direction="column" gap="1">
          <Text size="5" weight="bold">Project Name</Text>
          <Text size="2" className="text-[--accent-9]">
            Used to identify your Project on the Dashboard.
          </Text>
        </Flex>

        <TextField.Root size="3" placeholder="" value={name} onChange={e => setName(e.target.value)}>
          <TextField.Slot>
            <Badge className="text-[--gray-a9]" color="gray">
              dashboard.vivgrid.com/
              {teamStore.currentTeam.slug}
              /
            </Badge>
          </TextField.Slot>
        </TextField.Root>

        <Inset my="6">
          <Separator size="4" />
        </Inset>
        <Flex justify="between" align="center">
          <Text size="1" className="text-balance text-[--gray-a9]">
            Project names can be up to 100 characters long and must be lowercase. They can include letters, digits, and the following characters:
            {' '}
            <code>.</code>
            {' '}
            {' '}
            <code>_</code>
            {' '}
            {' '}
            <code>-</code>
          </Text>
          <Button onClick={handleSave}>Save</Button>
        </Flex>
      </Flex>
    </Card>
  )
}
