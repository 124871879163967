import { Box, Flex, Heading } from '@radix-ui/themes'

interface ContentProps {
  children: React.ReactNode
  title: string
  row?: boolean
}

export function Content({ children, title, row }: ContentProps) {
  return (
    <Box className="flex h-full flex-1 flex-col">
      <Flex className="shrink-0 grow-0 border-b border-[--accent-5]" px="4" height="6.55rem" align="center">
        <Heading size="8">
          {title}
        </Heading>
      </Flex>
      <Flex p="6" direction={row ? 'row' : 'column'} className="relative flex-1">
        {children}
      </Flex>
    </Box>
  )
}
