import { proxy, useSnapshot } from 'valtio'
import useSWR from 'swr'
import { teamStore } from './team'
import type { ApiResult } from '~/libs/fetcher'
import { apiFetch } from '~/libs/fetcher'

export interface ProjectItem {
  id: string
  name: string
  description: string
  repo: string
  url: string
  aiPrompt: string
  avatar: string
  teamId: string
  appId: string
  appKey: string
  appSecret: string
  createdAt: string
  updatedAt: string | null
}

export const projectStore = proxy({
  currentProject: {} as ProjectItem,
})

export const useProjectStore = () => useSnapshot(projectStore)

export async function fetchCurrentProject(projectName: string) {
  const res = await apiFetch<ApiResult<ProjectItem>>(`/teams/${teamStore.currentTeam.id}/projects/${projectName}`)

  if (!res.ok)
    throw new Error('Failed to fetch project')

  projectStore.currentProject = res.data
}

export function setCurrentProject(project: ProjectItem) {
  projectStore.currentProject = project
}

export function useProjects(teamId: string) {
  const { data, error, isLoading } = useSWR(['/projects', teamId], async () => {
    const res = await apiFetch<ApiResult<ProjectItem[]>>(`/teams/${teamId}/projects`)
    if (!res.ok)
      throw new Error('Failed to fetch projects')

    return res.data
  })

  return {
    projects: data!,
    error,
    isLoading,
  }
}

export async function updateAiPrompt(teamId: string, projectId: string, prompt: string) {
  const res = await apiFetch<ApiResult<void>>(`/teams/${teamId}/projects/${projectId}/prompt`, {
    method: 'PATCH',
    body: {
      prompt,
    },
  })
  return res.ok
}

interface FunctionItem {
  sfn_name: string
  running: boolean
  hosting: 'hosting' | 'self'
  obversed_tag?: number[]
  ai: boolean
}

export function useFunctions(teamId: string, projectId: string) {
  // return {
  //   functions: [{
  //     sfn_name: 'Demo fn',
  //     running: true,
  //     hosting: false,
  //     obversed_tag: [1, 23],
  //   }, {
  //     sfn_name: 'Demo AI fn',
  //     running: true,
  //     hosting: true,
  //     obversed_tag: [323, 567],
  //     ai: true,
  //   }, {
  //     sfn_name: 'None fn',
  //     running: false,
  //     hosting: true,
  //     obversed_tag: [2],
  //     ai: true,
  //   }] as FunctionItem[],
  //   error: null,
  //   isLoading: false,
  // }

  const { data, error, isLoading } = useSWR(['/functions', teamId, projectId], async () => {
    const res = await apiFetch<ApiResult<FunctionItem[]>>(`/teams/${teamId}/projects/${projectId}/sfn`)
    if (!res.ok)
      throw new Error('Failed to fetch functions')

    return res.data
  })

  return {
    functions: data!,
    error,
    isLoading,
  }
}

export function useTokenUsage(teamId: string, projectId: string) {
  const { data, error, isLoading } = useSWR(['/token/usage', teamId, projectId], async () => {
    const res = await apiFetch<ApiResult<{ input_tokens: number, output_tokens: number }>>(`/teams/${teamId}/projects/${projectId}/token/usage`)
    if (!res.ok)
      throw new Error('Failed to fetch ai token usage')

    return res.data
  })

  return {
    usage: data!,
    error,
    isLoading,
  }
}

// export function useProjectLog(teamId: string, projectId: string, sql: string) {
//   const { data, error, isLoading } = useSWR(['/log', teamId, projectId, sql], async () => {
//     const res = await apiFetch(`/teams/${teamId}/projects/${projectId}/log?sql=${sql}`)
//     if (!res.ok)
//       throw new Error('Failed to fetch log')
//   })
//
//   return {
//     log: data,
//     error,
//     isLoading,
//   }
// }
