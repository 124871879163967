import { Button, Card, Dialog, Flex, Text, TextField } from '@radix-ui/themes'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { mutate } from 'swr'
import { apiFetch } from '~/libs/fetcher'
import { useProjectStore } from '~/stores/project'

export function DeleteProject() {
  const navigate = useNavigate()
  const projectStore = useProjectStore()
  const [inputProjectName, setInputProjectName] = useState('')
  const [loading, setLoading] = useState(false)

  async function handleDelete() {
    setLoading(true)
    try {
      const res = await apiFetch(`/teams/${projectStore.currentProject.teamId}/projects/${projectStore.currentProject.id}`, {
        method: 'DELETE',
      })
      if (res.ok) {
        mutate(['/projects', projectStore.currentProject.teamId])
        toast.success('Project deleted successfully')
        navigate(`/${projectStore.currentProject.teamId}`)
        return
      }

      toast.error('Failed to delete project')
    }
    catch (error) {
      toast.error('Failed to delete project')
    }
    finally {
      setLoading(false)
    }
  }

  const confirmDisabled = inputProjectName !== projectStore.currentProject.name

  return (
    <Card size="3" className="border border-destructive">
      <Flex direction="column" gap="4">
        <Flex direction="column" gap="1">
          <Text size="5" weight="bold" className="text-destructive dark:text-red-500">Delete Project</Text>
          <Text size="2" className="text-balance text-[--gray-a9]">
            Permanently delete your project and all of its contents from the platform. This action is not reversible, so please continue with caution.
          </Text>
        </Flex>

        <Flex justify="end" align="center">
          <Dialog.Root>
            <Dialog.Trigger>
              <Button className="bg-destructive text-destructive-foreground hover:bg-destructive/90">Delete Project</Button>
            </Dialog.Trigger>
            <Dialog.Content maxWidth="450px">
              <Dialog.Title>Are you absolutely sure?</Dialog.Title>
              <Dialog.Description size="2" mb="4">
                This action cannot be undone. This will permanently delete your project and remove all of its contents from our servers.
              </Dialog.Description>
              <label>
                <Flex align="center" gap="4">
                  <Text as="div" size="2" mb="1" weight="bold">
                    Project name
                  </Text>
                  <TextField.Root
                    className="flex-1"
                    value={inputProjectName}
                    placeholder={`Type "${projectStore.currentProject.name}" to confirm`}
                    onChange={e => setInputProjectName(e.target.value)}
                  />
                </Flex>
              </label>
              <Flex gap="3" mt="4" justify="end">
                <Dialog.Close>
                  <Button variant="soft" color="gray">
                    Cancel
                  </Button>
                </Dialog.Close>
                <Button loading={loading} onClick={handleDelete} disabled={confirmDisabled} className="bg-destructive text-destructive-foreground hover:bg-destructive/90 disabled:opacity-50">Delete Project</Button>
              </Flex>
            </Dialog.Content>
          </Dialog.Root>
        </Flex>
      </Flex>
    </Card>
  )
}
