import useSWR from 'swr'
import { proxy, useSnapshot } from 'valtio'
import type { ApiResult } from '~/libs/fetcher'
import { apiBillingFetch, apiFetch } from '~/libs/fetcher'

interface TeamItem {
  id: string
  slug: string
  name: string
  avatar: string
  ownerId: string
  createdAt: string
  updatedAt: string | null
}

interface TeamPlanItem {
  credits: number
  id: string
  live: boolean
  models: string[]
  name: string
  overtakes: number
  product_id: string
  project_sfns: number
  projects: number
  qpm: number
  sfns: number
  tpm: number
  zones: number
}

export const teamStore = proxy({
  allTeams: [] as TeamItem[],
  currentTeam: {} as TeamItem,
  allTeamsPlan: {} as Record<string, TeamPlanItem>,
})

export const useTeamStore = () => useSnapshot(teamStore)

export async function fetchAllTeams() {
  const data = await apiFetch<ApiResult<{ teams: TeamItem[] }>>('/teams')
  if (!data.ok) {
    // eslint-disable-next-line no-alert
    alert('Failed to fetch teams!')
    return
  }

  teamStore.allTeams = data.data.teams

  fetchAllTeamsPlan()
}

export async function fetchAllTeamsPlan() {
  let plans = await Promise.all(teamStore.allTeams.map(((team) => {
    return apiBillingFetch('/v1/plans/find', {
      params: {
        org_id: team.id,
      },
    }).then((res) => {
      if (!res.data)
        return [null, null]
      return [team.id, res.data]
    }).catch(() => {
      return [null, null]
    })
  })))
  if (plans.length === 0) {
    teamStore.allTeamsPlan = {}
    return
  }
  plans = plans.filter(plan => plan[0] !== null)
  teamStore.allTeamsPlan = Object.fromEntries(plans)
}

export function setCurrentTeam(teamSlug?: string) {
  const allTeams = teamStore.allTeams
  if (!teamSlug) {
    teamStore.currentTeam = allTeams[0]
    return
  }

  let currentTeam = allTeams.find(team => team.slug === teamSlug)
  if (!currentTeam)
    currentTeam = allTeams[0]

  teamStore.currentTeam = currentTeam
}

export async function fetchCurrentTeam() {
  await fetchAllTeams()
  setCurrentTeam(teamStore.currentTeam.slug)
}

interface TeamMember { id: number, teamId: string, createdAt: string, userId: string }

export function useTeamMembers(teamId: string) {
  const { data, error, isLoading, mutate } = useSWR<ApiResult<{ members: TeamMember[] }>>(`/teams/${teamId}/members`)

  if (!data || !data.ok) {
    if (data?.error) {
      return {
        members: [],
        error: new Error(data.error),
        isLoading,
        mutate,
      }
    }

    return {
      members: [],
      error,
      isLoading,
      mutate,
    }
  }

  return {
    members: data.data.members,
    error,
    isLoading,
    mutate,
  }
}
