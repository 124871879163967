import { proxy, useSnapshot } from 'valtio'
import type { ApiResult } from '~/libs/fetcher'
import { apiFetch } from '~/libs/fetcher'

interface Me {
  id: string
  name: string
  avatar: string
  email: string
  defaultTeamId: string
  invitation?: {
    email: string
    createdAt: string
    approvedAt: string | null
    rejectedAt: string | null
  }
}

export const userStore = proxy({
  me: {
    id: '',
    name: '',
    avatar: '',
    email: '',
    defaultTeamId: '',
  } as Me,
})

export const useUserStore = () => useSnapshot(userStore)

export async function fetchMe() {
  const data = await apiFetch<ApiResult<Exclude<Me, 'invitation'>>>('/users/me')
  // this should not happen
  if (!data.ok) {
    // eslint-disable-next-line no-alert
    alert('Failed to fetch current user!')
    return
  }

  userStore.me = data.data
}
