import { CopyIcon } from '@radix-ui/react-icons'
import { Code, DataList, IconButton, Separator } from '@radix-ui/themes'
import copy from 'copy-text-to-clipboard'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import { useState } from 'react'
import { toast } from 'sonner'
import { useProjectStore } from '~/stores/project'

const ZIPPER_ENDPOINT = 'zipper.vivgrid.com:9000'
// const OPEN_AI_BRIDGE = 'https://api.vivgrid.com'

export function BasicInfo() {
  const currentProject = useProjectStore().currentProject

  const [showSecret, setShowSecret] = useState(false)

  function copyText(text: string) {
    const success = copy(text)
    if (success)
      toast.success('Copied to clipboard')
    else
      toast.error('Failed to copy to clipboard')
  }

  return (
    <DataList.Root>
      <DataList.Item>
        <DataList.Label minWidth="88px">APP_ID</DataList.Label>
        <DataList.Value>
          <div className="flex items-center gap-2">
            <Code variant="ghost" className="select-all" onDoubleClick={() => { copyText(currentProject.appId) }}>{currentProject.appId}</Code>
          </div>
        </DataList.Value>
      </DataList.Item>
      <Separator size="4" className="col-span-2" />

      <DataList.Item>
        <DataList.Label minWidth="88px">APP_KEY</DataList.Label>
        <DataList.Value>
          <div className="flex items-center gap-2">
            <Code variant="ghost" className="select-all" onDoubleClick={() => { copyText(currentProject.appKey) }}>{currentProject.appKey}</Code>
            <IconButton
              size="1"
              aria-label="Copy value"
              color="gray"
              variant="ghost"
              onClick={() => {
                copyText(currentProject.appKey)
              }}
            >
              <CopyIcon />
            </IconButton>
          </div>
        </DataList.Value>
      </DataList.Item>
      <DataList.Item>
        <DataList.Label minWidth="88px">APP_SECRET</DataList.Label>
        <DataList.Value>
          <div className="flex items-center gap-2">
            <Code variant="ghost" className="select-all" onDoubleClick={() => { showSecret && copyText(currentProject.appKey) }}>{showSecret ? currentProject.appSecret : '******************************'}</Code>

            <IconButton
              size="1"
              aria-label="show value"
              color="gray"
              variant="ghost"
              onClick={() => {
                setShowSecret(!showSecret)
              }}
            >
              {showSecret ? <EyeIcon size={18} strokeWidth={1.25} /> : <EyeOffIcon size={18} strokeWidth={1.25} />}
            </IconButton>

            <IconButton
              size="1"
              aria-label="Copy value"
              color="gray"
              variant="ghost"
              onClick={() => {
                copyText(currentProject.appSecret)
              }}
            >
              <CopyIcon />
            </IconButton>
          </div>
        </DataList.Value>
      </DataList.Item>
      <Separator size="4" className="col-span-2" />

      <DataList.Item>
        <DataList.Label minWidth="88px">ZIPPER ENDPOINT</DataList.Label>
        <DataList.Value>
          <div className="flex items-center gap-2">
            <Code variant="ghost" className="select-all" onDoubleClick={() => { copyText(ZIPPER_ENDPOINT) }}>{ZIPPER_ENDPOINT}</Code>
            <IconButton
              size="1"
              aria-label="Copy value"
              color="gray"
              variant="ghost"
              onClick={() => {
                copyText(ZIPPER_ENDPOINT)
              }}
            >
              <CopyIcon />
            </IconButton>
          </div>
        </DataList.Value>
      </DataList.Item>
      {/* <DataList.Item>
        <DataList.Label minWidth="88px">OpenAI Bridge</DataList.Label>
        <DataList.Value>
          <div className="flex items-center gap-2">
            <Code variant="ghost" className="select-all" onDoubleClick={() => { copyText(OPEN_AI_BRIDGE) }}>{OPEN_AI_BRIDGE}</Code>
            <IconButton
              size="1"
              aria-label="Copy value"
              color="gray"
              variant="ghost"
              onClick={() => {
                copyText(OPEN_AI_BRIDGE)
              }}
            >
              <CopyIcon />
            </IconButton>
          </div>
        </DataList.Value>
      </DataList.Item> */}
    </DataList.Root>
  )
}
