import { Button, Card, Flex, Inset, Separator, Text, TextField } from '@radix-ui/themes'
import { useState } from 'react'
import { toast } from 'sonner'
import { apiFetch } from '~/libs/fetcher'
import { fetchMe, useUserStore } from '~/stores/user'

export function UpdateName() {
  const userStore = useUserStore()
  const [name, setName] = useState(userStore.me.name)

  async function handleSave() {
    if (name.length < 1 || name.length > 32) {
      toast.error('Name must be between 1 and 32 characters')
      return
    }

    try {
      const res = await apiFetch('/users/me/name', {
        method: 'PATCH',
        query: { name },
      })
      if (res.ok) {
        toast.success('Name updated')
        fetchMe()
        return
      }

      toast.error('Failed to update name')
    }
    catch (error) {
      toast.error('Failed to update name')
    }
  }

  return (
    <Card size="3">
      <Flex direction="column" gap="4">
        <Flex direction="column" gap="1">
          <Text size="5" weight="bold">Display Name</Text>
          <Text size="2" className="text-[--accent-9]">Please enter your full name, or a display name you are comfortable with.</Text>
        </Flex>

        <TextField.Root size="3" placeholder="" value={name} onChange={e => setName(e.target.value)} />

        <Inset my="6">
          <Separator size="4" />
        </Inset>
        <Flex justify="between" align="center">
          <Text size="2" className="text-[--gray-a9]">Please use 32 characters at maximum.</Text>
          <Button onClick={handleSave}>Save</Button>
        </Flex>
      </Flex>
    </Card>
  )
}
