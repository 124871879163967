import type { LogtoConfig } from '@logto/browser'

const isProd = import.meta.env.PROD

const billingDebugMode = !!(!isProd || new URLSearchParams(location.search).get('debug'))

export const config = {
  isProd,
  billingDebugMode,
  apiBaseURL: isProd
    ? 'https://dashboardapi.vivgrid.com'
    : import.meta.env.VITE_API_BASE_URL || 'https://dashboardapi.vivgrid.com',

  logto: {
    endpoint: 'https://auth.vivgrid.com/',
    appId: 'ubtbati3q8yqfeuqql00g',
    resources: ['https://dashboardapi.vivgrid.com'],
  } as LogtoConfig,
  stripe: {
    priceId: {
      growth: billingDebugMode ? 'price_1QHgIRGRyDkB6J5kbhShM0LG' : 'price_1QHgkoGRyDkB6J5ko8BtdU1Q',
    },
  },
}
